export const ContentBlockType = Object.freeze({
    WYSIWYG: "wysiwyg",
    IMAGE: "image",
    ANIMATED_SUMMARY_LIST: "animated_summary_list",
    QUIZ_BOARD_PREDEFINED_CONTENT: "quiz_board_predefined_content",
    QUIZ_NEXT_BOARD_BUTTON_PLACEHOLDER: "quiz_next_board_button_placeholder",
    CHART: "chart",
    ROW_2COL: "row_2col"
});
export const ContentBlockTypeLabel = Object.freeze({
    [ContentBlockType.WYSIWYG]: "Tekst formatowany",
    [ContentBlockType.IMAGE]: "Obraz",
    [ContentBlockType.ANIMATED_SUMMARY_LIST]: "Animowane podsumowanie w formie listy",
    [ContentBlockType.QUIZ_BOARD_PREDEFINED_CONTENT]: "Zawartość generowana planszy",
    [ContentBlockType.QUIZ_NEXT_BOARD_BUTTON_PLACEHOLDER]:
        "Przycisk przejścia do następnej planszy",
    [ContentBlockType.CHART]: "Wykres",
    [ContentBlockType.ROW_2COL]: "Wiersz 2-kolumnowy"
});
export const ContentBlockTypeDescription = Object.freeze({
    [ContentBlockType.QUIZ_BOARD_PREDEFINED_CONTENT]: "Zależna od jej typu",
    [ContentBlockType.QUIZ_NEXT_BOARD_BUTTON_PLACEHOLDER]:
        "Do wyrenderowania w treści planszy zamiast na dole ekranu"
});
export const ContentBlockTypeSelectItems = Object.values(ContentBlockType).map(it => {
    return {
        text: ContentBlockTypeLabel[it],
        description: ContentBlockTypeDescription[it],
        value: it
    };
});

export const ContentBlockAnimatedSummaryListItemIcon = Object.freeze({
    CHECKBOX: "checkbox"
});
export const ContentBlockAnimatedSummaryListItemIconLabel = Object.freeze({
    [ContentBlockAnimatedSummaryListItemIcon.CHECKBOX]: "Checkbox"
});
export const ContentBlockAnimatedSummaryListItemIconName = Object.freeze({
    [ContentBlockAnimatedSummaryListItemIcon.CHECKBOX]: "mdi-check-circle"
});
export const ContentBlockAnimatedSummaryListItemIconSelectItems = Object.values(
    ContentBlockAnimatedSummaryListItemIcon
).map(it => {
    return {
        text: ContentBlockAnimatedSummaryListItemIconLabel[it],
        icon: ContentBlockAnimatedSummaryListItemIconName[it],
        value: it
    };
});

export const ContentBlockChartType = Object.freeze({
    LINE: "line",
    AREA: "area",
    BAR: "bar",
    COLUMN: "column",
    PIE: "pie",
    RADAR: "radar",
    GAUGE: "gauge"
});
export const ContentBlockChartTypeLabel = Object.freeze({
    [ContentBlockChartType.LINE]: "Liniowy",
    [ContentBlockChartType.AREA]: "Powierzchniowy",
    [ContentBlockChartType.BAR]: "Słupkowy",
    [ContentBlockChartType.COLUMN]: "Kolumnowy",
    [ContentBlockChartType.PIE]: "Kołowy",
    [ContentBlockChartType.RADAR]: "Radarowy",
    [ContentBlockChartType.GAUGE]: "Miernikowy"
});
export const ContentBlockChartTypeSelectItems = Object.values(ContentBlockChartType).map(it => {
    return {
        text: ContentBlockChartTypeLabel[it],
        value: it
    };
});

export const ContentBlockChartGaugeLabelOffset = Object.freeze({
    TOP: "top",
    CENTER: "center",
    BOTTOM: "bottom"
});
export const ContentBlockChartGaugeLabelOffsetLabel = Object.freeze({
    [ContentBlockChartGaugeLabelOffset.TOP]: "Przesunięta w górę",
    [ContentBlockChartGaugeLabelOffset.CENTER]: "Na środku",
    [ContentBlockChartGaugeLabelOffset.BOTTOM]: "Przesunięta w dół"
});
export const ContentBlockChartGaugeLabelOffsetSelectItems = Object.values(
    ContentBlockChartGaugeLabelOffset
).map(it => {
    return {
        text: ContentBlockChartGaugeLabelOffsetLabel[it],
        value: it
    };
});

export const ContentBlockChartGaugeTheme = Object.freeze({
    QL1_DEFAULT: "ql1_default",
    QL2_DEFAULT: "ql2_default"
});
export const ContentBlockChartGaugeThemeLabel = Object.freeze({
    [ContentBlockChartGaugeTheme.QL1_DEFAULT]: "Layout 1 (domyślny)",
    [ContentBlockChartGaugeTheme.QL2_DEFAULT]: "Layout 2 (domyślny)"
});
export const ContentBlockChartGaugeThemeSelectItems = Object.values(
    ContentBlockChartGaugeTheme
).map(it => {
    return {
        text: ContentBlockChartGaugeThemeLabel[it],
        value: it
    };
});

export const ContentBlockRow2ColLayout = Object.freeze({
    _40_60: "40/60",
    _50_50: "50/50",
    _60_40: "60/40"
});
export const ContentBlockRow2ColLayoutSelectItems = Object.values(ContentBlockRow2ColLayout).map(
    it => {
        return {
            text: it,
            value: it
        };
    }
);
