"use strict";

export const DocumentTemplateTag = Object.freeze({
    CONTRACT_DATE: "contract_date",
    PARTNER_FIRST_NAME: "partner_first_name",
    PARTNER_LAST_NAME: "partner_last_name",
    PARTNER_PESEL: "partner_pesel",
    PARTNER_COMPANY_NAME: "partner_company_name",
    PARTNER_ADDRESS_ZIP_CODE: "partner_address_zip_code",
    PARTNER_ADDRESS_CITY: "partner_address_city",
    PARTNER_ADDRESS_STREET: "partner_address_street",
    PARTNER_ADDRESS_BUILDING_NO: "partner_address_building_no",
    PARTNER_ADDRESS_APARTMENT_NO: "partner_address_apartment_no",
    PARTNER_TIN: "partner_tin",
    PARTNER_REGON: "partner_regon",
    PARTNER_KRS: "partner_krs",
    PARTNER_CONTACT_EMAIL: "partner_contact_email",
    PARTNER_REPRESENTATIVE_FIRST_NAME: "partner_representative_first_name",
    PARTNER_REPRESENTATIVE_LAST_NAME: "partner_representative_last_name",
    PARTNER_REPRESENTATIVE_POSITION: "partner_representative_position",

    PARTNERSHIP_START_DATE: "partnership_start_date",
    THS_CONTRACT_SLOTS_COUNT_POINT: "ths_contract_slots_count_point",
    THS_CONTRACT_SALARY_POINT: "ths_contract_salary_point",
    THS_CONTRACT_PRODUCTS_POINT: "ths_contract_products_point",
    TH_FLEXIBLE_SALARY_POINT_V1: "th_flexible_salary_point_v1",

    // CONDITIONALS
    CONDITIONAL_HEADER_PARTNER_V1: "conditional:header_partner_v1",

    // COMPONENTS
    COMPONENT_HEADER_RISIFY_KBOREK: "component:header_risify_kborek",
    COMPONENT_HEADER_PARTNER_V1_NATURAL_PERSON: "component:header_partner_v1_natural_person",
    COMPONENT_HEADER_PARTNER_V1_SOLE_PROPRIETORSHIP:
        "component:header_partner_v1_sole_proprietorship",
    COMPONENT_HEADER_PARTNER_V1_COMPANY: "component:header_partner_v1_company",
    COMPONENT_SIGNATURES_KBOREK_PARTNER_V1: "component:signatures_kborek_partner_v1"
});

export const DocumentTemplateTagDescription = Object.freeze({
    [DocumentTemplateTag.CONTRACT_DATE]:
        "Data zawarcia umowy - tag uzupełniany dopiero w momencie podpisania dokumentu",
    [DocumentTemplateTag.PARTNER_FIRST_NAME]:
        "Imię osoby, z którą Risify podpisuje umowę (dla os. fiz. i JDG)",
    [DocumentTemplateTag.PARTNER_LAST_NAME]:
        "Nazwisko osoby, z którą Risify podpisuje umowę (dla os. fiz. i JDG)",
    [DocumentTemplateTag.PARTNER_PESEL]: "Numer PESEL osoby, z którą Risify podpisuje umowę",
    [DocumentTemplateTag.PARTNER_COMPANY_NAME]: "Nazwa firmy osoby, z którą Risify podpisuje umowę",
    [DocumentTemplateTag.PARTNER_ADDRESS_ZIP_CODE]:
        "Kod pocztowy adresu strony, z którą Risify podpisuje umowę",
    [DocumentTemplateTag.PARTNER_ADDRESS_CITY]:
        "Miasto adresu strony, z którą Risify podpisuje umowę",
    [DocumentTemplateTag.PARTNER_ADDRESS_STREET]:
        "Ulica adresu strony, z którą Risify podpisuje umowę",
    [DocumentTemplateTag.PARTNER_ADDRESS_BUILDING_NO]:
        "Numer budynku adresu strony, z którą Risify podpisuje umowę",
    [DocumentTemplateTag.PARTNER_ADDRESS_APARTMENT_NO]:
        "Numer lokalu adresu strony, z którą Risify podpisuje umowę",
    [DocumentTemplateTag.PARTNER_TIN]: "NIP strony, z którą Risify podpisuje umowę",
    [DocumentTemplateTag.PARTNER_REGON]: "REGON strony, z którą Risify podpisuje umowę",
    [DocumentTemplateTag.PARTNER_KRS]: "KRS strony, z którą Risify podpisuje umowę",
    [DocumentTemplateTag.PARTNER_CONTACT_EMAIL]:
        "Adres e-mail kontaktowy strony, z którą Risify podpisuje umowę",
    [DocumentTemplateTag.PARTNER_REPRESENTATIVE_FIRST_NAME]:
        "Imię osoby reprezentującej firmę, z którą Risify podpisuje umowę",
    [DocumentTemplateTag.PARTNER_REPRESENTATIVE_LAST_NAME]:
        "Nazwisko osoby reprezentującej firmę, z którą Risify podpisuje umowę",
    [DocumentTemplateTag.PARTNER_REPRESENTATIVE_POSITION]:
        "Stanowisko osoby reprezentującej firmę, z którą Risify podpisuje umowę",

    [DocumentTemplateTag.PARTNERSHIP_START_DATE]:
        "Data rozpoczęcia współpracy (dla rekrutacji terapeutów)",
    [DocumentTemplateTag.THS_CONTRACT_SLOTS_COUNT_POINT]:
        "Punkt w umowie dotyczący ilości udostępnianych slotów w ramach umowy stałej (dla rekrutacji terapeutów)",
    [DocumentTemplateTag.THS_CONTRACT_SALARY_POINT]:
        "Punkt w umowie dotyczący stawki w ramach umowy stałej (dla rekrutacji terapeutów)",
    [DocumentTemplateTag.THS_CONTRACT_PRODUCTS_POINT]:
        "Punkt w umowie dotyczący produktów objętych umową stałą (dla rekrutacji terapeutów)",
    [DocumentTemplateTag.TH_FLEXIBLE_SALARY_POINT_V1]:
        "Punkt w umowie dotyczący stawek w ramach umowy elastycznej (dla rekrutacji terapeutów)",

    // CONDITIONALS
    [DocumentTemplateTag.CONDITIONAL_HEADER_PARTNER_V1]:
        "Element, który rozwija się w nagłówek strony właściwy dla typu podmiotu podpisującego umowę z Risify (dla rekrutacji terapeutów)",

    // COMPONENTS
    [DocumentTemplateTag.COMPONENT_HEADER_RISIFY_KBOREK]:
        "Element, który rozwija się w nagłówek umowy opisujący stronę Risify (z Karoliną Borek jako Prezes Zarządu)",
    [DocumentTemplateTag.COMPONENT_HEADER_PARTNER_V1_NATURAL_PERSON]:
        "Element, który rozwija się w nagłówek umowy opisujący stronę podpisującą umowę z Risify - osoba fizyczna lub działalność nierejestrowana",
    [DocumentTemplateTag.COMPONENT_HEADER_PARTNER_V1_SOLE_PROPRIETORSHIP]:
        "Element, który rozwija się w nagłówek umowy opisujący stronę podpisującą umowę z Risify - JDG",
    [DocumentTemplateTag.COMPONENT_HEADER_PARTNER_V1_COMPANY]:
        "Element, który rozwija się w nagłówek umowy opisujący stronę podpisującą umowę z Risify - firma lub organizacja",
    [DocumentTemplateTag.COMPONENT_SIGNATURES_KBOREK_PARTNER_V1]:
        "Element, który rozwija się w blok z pospisami pod umową, gdzie ze strony Risify podpisuje się Karolina Borek"
});

export const DocumentTemplateTagSelectItems = Object.values(DocumentTemplateTag).map(it => ({
    text: `[${it}]`,
    value: it
}));
