<template>
    <v-dialog
        v-model="value"
        persistent
        max-width="650"
        scrollable
    >
        <v-card
            v-if="value"
            class="wkmm-fs"
        >
            <v-card-title>
                <div>
                    Przenieś "{{ subjectName }}"

                    <div class="text-body-2 mt-1">
                        Obecna lokalizacja:
                        <v-btn
                            outlined
                            rounded
                            color="grey darken-1"
                            small
                            class="ml-1"
                            style="text-transform: none"
                            @click="loadFolder(currentFolder._id)"
                        >
                            <v-icon
                                class="mr-1"
                                size="16"
                                >mdi-folder</v-icon
                            >
                            {{ currentFolder.name }}
                        </v-btn>
                    </div>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text
                style="max-height: 70vh"
                class="pt-5"
            >
                <div class="py-2 text-body-1 black--text">
                    <v-btn
                        icon
                        small
                        class="mr-1"
                        color="black"
                        v-if="loaded_folder"
                        @click="loadFolder(loaded_folder.parent_folder)"
                    >
                        <v-icon size="20">mdi-arrow-left</v-icon>
                    </v-btn>
                    {{ loaded_folder ? loaded_folder.name : "Folder główny" }}
                </div>
                <v-divider></v-divider>
                <div
                    style="overflow-y: auto; max-height: 50vh"
                    class="mt-2"
                >
                    <v-fade-transition mode="out-in">
                        <div
                            v-if="loading_folder"
                            key="loader"
                        >
                            <v-skeleton-loader
                                type="list-item"
                                v-for="i in 5"
                                :key="i"
                            />
                        </div>
                        <div
                            v-else
                            key="content"
                        >
                            <div v-if="loaded_folder == null">
                                <WkMediaManagerFolderSelectEntity
                                    entity-type="folder"
                                    :item="{ _id: null }"
                                    :selected-id="selected_folder_id"
                                    @folder-select="
                                        id => {
                                            if (selected_folder_id != id) {
                                                selected_folder_id = id;
                                            } else {
                                                selected_folder_id = '';
                                            }
                                        }
                                    "
                                />
                                <div class="py-2">
                                    <v-divider />
                                </div>
                            </div>
                            <WkMediaManagerFolderSelectEntity
                                v-for="entry in folders"
                                :key="'Folder:' + entry._id"
                                entity-type="folder"
                                :item="entry"
                                :selected-id="selected_folder_id"
                                :disabled="entry._id == subjectId"
                                class="mb-1"
                                @folder-select="
                                    id => {
                                        if (selected_folder_id != id) {
                                            selected_folder_id = id;
                                        } else {
                                            selected_folder_id = '';
                                        }
                                    }
                                "
                                @open-folder="loadFolder"
                            />
                            <div
                                style="height: 1px"
                                v-intersect="folderIoCb"
                            ></div>
                            <div
                                class="py-2 text-center"
                                v-if="folders_loading"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    size="16"
                                    width="2"
                                ></v-progress-circular>
                            </div>

                            <!-- FILES -->
                            <WkMediaManagerFolderSelectEntity
                                v-for="entry in files"
                                :key="'File:' + entry._id"
                                entity-type="file"
                                :item="entry"
                                disabled
                                class="mb-1"
                            />
                            <div
                                style="height: 1px"
                                v-intersect="filesIoCb"
                            ></div>
                            <div
                                class="py-2 text-center"
                                v-if="files_loading"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    size="16"
                                    width="2"
                                ></v-progress-circular>
                            </div>

                            <div
                                class="text-caption grey--text"
                                v-if="folders.length == 0 && files.length == 0"
                            >
                                Folder jest pusty
                            </div>
                        </div>
                    </v-fade-transition>
                </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    text
                    color="grey lighten-1"
                    :disabled="loading"
                    @click="modelEmit(false)"
                >
                    Anuluj
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    :loading="loading"
                    :disabled="selected_folder_id == ''"
                    @click="$emit('folder-selected', selected_folder_id)"
                >
                    Przenieś
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import WkMediaManagerFolderSelectEntity from "@/components/WkMediaManager/WkMediaManagerFolderSelectEntity.vue";
import waitForMs from "@/helpers/waiters";

export default {
    components: {
        WkMediaManagerFolderSelectEntity
    },

    props: {
        value: {
            type: Boolean,
            default: false
        },
        subjectName: {
            type: String,
            default: ""
        },
        subjectId: {
            type: String
        },
        currentFolder: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            loaded_folder: undefined,
            loading_folder: false,

            selected_folder_id: "",

            folders: [],
            folders_loading: false,
            folders_loaded: false,
            folders_current_page: 0,
            folders_number_of_pages: 1,

            files: [],
            files_loading: false,
            files_loaded: false,
            files_current_page: 0,
            files_number_of_pages: 1
        };
    },

    watch: {
        value(nv, ov) {
            if (nv && nv != ov) {
                this.loadFolder(this.currentFolder._id);
            }
        }
    },

    methods: {
        modelEmit(v) {
            // console.log(v);
            this.$emit("input", v);
        },

        async loadFolder(id = null) {
            if (
                this.loading_folder ||
                (this.loaded_folder === null && id == null) ||
                (this.loaded_folder != null && this.loaded_folder._id == id)
            ) {
                return;
            }
            this.loading_folder = true;

            await waitForMs(10);

            // 1. Jeżeli id to null, to otwieramy root folder
            if (id === null) {
                this.loaded_folder = null;
            }
            // 2. W przeciwnym razie pobieramy dane nowego folderu
            else {
                const MF = await this.$axios.$get(`/mediamanager/folders/${id}`);
                this.loaded_folder = MF.mediamanager_folder;
            }

            // 4. Czyszczenie danych
            this.selected_folder_id = "";

            this.folders = [];
            this.folders_loaded = false;
            this.folders_current_page = 0;
            this.folders_number_of_pages = 1;

            this.files = [];
            this.files_loaded = false;
            this.files_current_page = 0;
            this.files_number_of_pages = 1;

            await this.fetchData("folders");
            await this.fetchData("files");

            this.loading_folder = false;
        },

        async fetchData(data_type = "folders") {
            if (
                this[data_type + "_loading"] ||
                this[data_type + "_current_page"] >= this[data_type + "_number_of_pages"]
            ) {
                return;
            }

            this[data_type + "_loading"] = true;
            // await waitForMs(1000);

            try {
                const r = await this.$axios.$get(
                    `/mediamanager/${data_type}/?page=${
                        this[data_type + "_current_page"] + 1
                    }&filter_parent_folder=${
                        this.loaded_folder ? this.loaded_folder._id : null
                    }&items_per_page=32`
                );

                const response_prop = "mediamanager_" + data_type;
                for (let i = 0; i < r[response_prop].length; i++) {
                    const ix = this[data_type].findIndex(it => it._id == r[response_prop][i]._id);
                    if (ix === -1) {
                        this[data_type].push(r[response_prop][i]);
                    }
                }

                this[data_type + "_loaded"] = true;
                this[data_type + "_current_page"] = r.pagination.current_page;
                this[data_type + "_number_of_pages"] = r.pagination.number_of_pages;
            } catch (err) {
                console.error(err);
            }

            this[data_type + "_loading"] = false;
        },
        folderIoCb(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.fetchData("folders");
            }
        },
        filesIoCb(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.fetchData("files");
            }
        }
    }

    // mounted() {
    //     if (!this.$store.state.initial_layout_set) return;
    //     this.modelEmit(true);
    // }
};
</script>
