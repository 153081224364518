"use strict";

export const PaymentIntentCurrency = Object.freeze({
    PLN: "pln"
});

export const PaymentIntentCurrencyLabel = Object.freeze({
    [PaymentIntentCurrency.PLN]: "zł"
});

export const PaymentIntentPaymentMethod = Object.freeze({
    STRIPE_CARD_ON_SESSION: "stripe_card_on_session",
    STRIPE_P24: "stripe_p24"
});

export const PaymentIntentPaymentMethodLabel = Object.freeze({
    [PaymentIntentPaymentMethod.STRIPE_CARD_ON_SESSION]: "Płatność kartą online",
    [PaymentIntentPaymentMethod.STRIPE_P24]: "Przelewy24"
});

export const PaymentIntentPaymentStatus = Object.freeze({
    CREATED: "created",
    INITIALIZED: "initialized",
    PAID: "paid",
    ERROR: "error",
    REFUNDED: "refunded",
    CANCELED: "canceled"
});

export const PaymentIntentPaymentStatusLabel = Object.freeze({
    [PaymentIntentPaymentStatus.CREATED]: "Utworzona",
    [PaymentIntentPaymentStatus.INITIALIZED]: "Rozpoczęta",
    [PaymentIntentPaymentStatus.PAID]: "Zakończona",
    [PaymentIntentPaymentStatus.ERROR]: "Błąd",
    [PaymentIntentPaymentStatus.REFUNDED]: "Zwrócona",
    [PaymentIntentPaymentStatus.CANCELED]: "Anulowana"
});

export const PaymentIntentPaymentStatusColor = Object.freeze({
    [PaymentIntentPaymentStatus.CREATED]: "#F8F8F8",
    [PaymentIntentPaymentStatus.INITIALIZED]: "primary",
    [PaymentIntentPaymentStatus.PAID]: "success",
    [PaymentIntentPaymentStatus.ERROR]: "error",
    [PaymentIntentPaymentStatus.REFUNDED]: "secondary",
    [PaymentIntentPaymentStatus.CANCELED]: "#EBEBEB"
});

export const PaymentIntentPaymentStatusChip = Object.values(PaymentIntentPaymentStatus).reduce(
    (acc, v) => {
        acc[v] = {
            value: v,
            label: PaymentIntentPaymentStatusLabel[v],
            color: PaymentIntentPaymentStatusColor[v]
        };
        return acc;
    },
    {}
);
