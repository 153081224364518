<template>
    <component
        :class="{
            'user-tile d-flex align-center text-decoration-none': true,
            'user-tile--clickable': clickDetails
        }"
        @click="showDetails"
        :is="tag"
        :to="to"
    >
        <template v-if="value">
            <v-menu
                :close-on-content-click="false"
                :nudge-width="192"
                outlined
                :disabled="!contactPopover"
                open-on-hover
            >
                <template v-slot:activator="{ on, attrs }">
                    <component
                        class="user-tile d-flex align-center text-decoration-none"
                        :is="tag"
                        :to="to"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-avatar
                            :class="{
                                'mr-2': computedAvatarSize > 16,
                                'mr-1': computedAvatarSize <= 16
                            }"
                            :size="computedAvatarSize"
                            color="primary"
                        >
                            <v-img
                                v-if="value && value.profile_image"
                                :src="value.profile_image.url_sm"
                            />
                            <div
                                class="text-center white--text font-weight-medium"
                                v-else
                                :style="{
                                    'font-size': `${Math.max(
                                        0.5,
                                        0.05 * (computedAvatarSize / 2)
                                    )}rem`,
                                    'line-height': 1
                                }"
                            >
                                {{ $store.getters["auth/userInitials"](value) }}
                            </div>
                        </v-avatar>
                        <div class="text-body-2">
                            <div
                                class="font-weight-medium"
                                style="color: #434343"
                            >
                                {{ value.first_name }} {{ value.last_name ? value.last_name : "" }}
                                <span
                                    class="font-weight-regular"
                                    v-if="
                                        !hideNameDetails &&
                                        $store.state.auth.value &&
                                        $store.state.auth.value._id == value._id
                                    "
                                >
                                    (Ty)
                                </span>
                            </div>
                            <div
                                class="grey--text text-caption"
                                style="margin-top: -4px"
                            >
                                <template v-if="detailsKey == 'role'">
                                    {{ user_role_labels[value.role] }}
                                </template>
                                <template v-else-if="value[detailsKey] !== undefined">
                                    {{ value[detailsKey] }}
                                </template>
                                <template v-else>
                                    {{ detailsKey }}
                                </template>
                            </div>
                        </div>
                    </component>
                </template>
                <v-card>
                    <v-card-title class="pa-2 px-4 text-caption"
                        >{{ value.first_name }} {{ value.last_name }}</v-card-title
                    >
                    <v-divider />
                    <v-card-text>
                        <div
                            class="text-subtitle-1 grey--text d-flex align-center"
                            style="cursor: pointer"
                            @click="copyString(value.email)"
                        >
                            <v-icon
                                size="16"
                                class="mr-2"
                                >mdi-at</v-icon
                            >
                            {{ value.email }}
                            <v-btn
                                icon
                                color="grey"
                                x-small
                            >
                                <v-icon size="12">mdi-content-copy</v-icon>
                            </v-btn>
                        </div>
                        <div
                            v-if="value.phone_cc && value.phone"
                            class="text-subtitle-1 grey--text d-flex align-center"
                        >
                            <v-icon
                                size="16"
                                class="mr-2"
                                >mdi-phone</v-icon
                            >
                            <a :href="`tel:${value.phone_cc}${value.phone}`">{{
                                `+${value.phone_cc} ${value.phone}`
                            }}</a>
                        </div>
                    </v-card-text>
                </v-card>
            </v-menu>
        </template>
        <template v-else>
            <v-skeleton-loader
                class="mr-2"
                :width="computedAvatarSize"
                :height="computedAvatarSize"
                type="avatar"
            />
            <div>
                <v-skeleton-loader
                    width="90"
                    height="12"
                    type="text"
                />
                <v-skeleton-loader
                    width="60"
                    height="8"
                    type="text"
                    class="mt-1"
                />
            </div>
        </template>
    </component>
</template>

<script>
import { UserRoleLabel } from "../enums/User";

export default {
    props: {
        value: {
            type: Object
        },
        tag: {
            type: String,
            default: "div"
        },
        to: {
            type: [String, Object],
            default: ""
        },
        detailsKey: {
            type: String,
            default: "role"
        },
        avatarSize: {
            type: [Number, String],
            default: 42
        },
        hideNameDetails: {
            type: Boolean,
            default: false
        },
        contactPopover: {
            type: Boolean,
            default: false
        },
        clickDetails: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            user_role_labels: UserRoleLabel
        };
    },

    computed: {
        computedAvatarSize() {
            return parseInt(this.avatarSize);
        }
    },

    methods: {
        copyString(string) {
            this.$copyText(string).then(() => {
                this.$message({
                    type: "success",
                    msg: "Skopiowano!"
                });
            });
        },

        showDetails() {
            if (this.clickDetails) {
                const routeData = this.$router.resolve({
                    name: "users__edit",
                    params: {
                        id: this.value._id
                    }
                });
                window.open(routeData.href, "_blank");
            }
        }
    }
};
</script>
