<template>
    <div class="wkmm-fpr">
        <template v-if="file.processed">
            <v-img
                v-if="file.url_thumb && !preview_as_original && !image_preview_error"
                :lazy-src="file.url_placeholder"
                :src="file.url_thumb"
                cover
                width="100%"
                height="100%"
                @error="image_preview_error = true"
            ></v-img>
            <v-img
                v-else-if="
                    file.display_type == MediamanagerFileDisplayType.IMAGE &&
                    preview_as_original &&
                    !image_preview_error
                "
                :src="file.url"
                contain
                width="100%"
                height="100%"
                @error="image_preview_error = true"
            ></v-img>
            <div
                class="wkmm-fpr__picon"
                v-else
            >
                <WkMediaManagerFileIcon
                    :file-type="file.display_type"
                    size="48"
                />
            </div>
            <div
                class="wkmm-fpr__play"
                v-if="is_openable"
            >
                <v-btn
                    rounded
                    color="grey lighten-2"
                    style="min-width: 36px"
                    class="px-0"
                    @click.stop="plpr_dialog = true"
                >
                    <v-icon
                        v-if="
                            file.display_type === MediamanagerFileDisplayType.VIDEO ||
                            file.display_type === MediamanagerFileDisplayType.SOUND
                        "
                        >mdi-play</v-icon
                    >
                    <v-icon v-else>mdi-eye</v-icon>
                </v-btn>
            </div>
        </template>
        <div
            v-else
            style="width: 100%; height: 100%"
            class="d-flex align-center justify-center flex-column"
        >
            <v-progress-circular
                indeterminate
                color="primary"
                size="16"
                width="1"
            />
            <div
                class="grey--text mt-2"
                style="font-size: 0.6rem; line-height: 1"
            >
                Przetwarzanie pliku...
            </div>
        </div>

        <v-dialog v-model="plpr_dialog">
            <div v-if="plpr_dialog">
                <div class="grey darken-3 d-flex align-center justify-space-between pa-4">
                    <div class="text-body-1 white--text">
                        {{ file.name }}
                    </div>
                    <div class="ml-2 flex-shrink-0">
                        <v-btn
                            icon
                            color="white"
                            @click="plpr_dialog = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </div>
                <iframe
                    :src="file.url"
                    frameborder="0"
                    v-if="file.display_type === MediamanagerFileDisplayType.PDF"
                    style="display: block; width: 100%; height: 80vh; position: relative"
                ></iframe>
                <div
                    class="grey darken-3"
                    style="display: block; width: 100%; height: 80vh"
                    v-else
                >
                    <v-img
                        contain
                        :src="file.url"
                        :lazy-src="file.url_placeholder"
                        v-if="file.display_type === MediamanagerFileDisplayType.IMAGE"
                        width="100%"
                        height="100%"
                    ></v-img>
                    <video
                        :src="file.url"
                        :poster="file.url_hq"
                        controls
                        style="display: block; width: 100%; height: 100%"
                        v-if="file.display_type === MediamanagerFileDisplayType.VIDEO"
                    ></video>
                    <audio
                        :src="file.url"
                        :poster="file.url_hq"
                        controls
                        style="
                            display: block;
                            position: absolute;
                            width: 80%;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        "
                        v-if="file.display_type === MediamanagerFileDisplayType.SOUND"
                    ></audio>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import WkMediaManagerFileIcon from "./WkMediaManagerFileIcon.vue";
import { MediamanagerFileDisplayType } from "../../enums/Mediamanager";

export default {
    components: {
        WkMediaManagerFileIcon
    },

    props: {
        file: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            image_preview_error: false,

            plpr_dialog: false,

            MediamanagerFileDisplayType
        };
    },

    computed: {
        ext() {
            if (!this.file) return "";

            let s = this.file.name;
            if (this.file.url != undefined) s = this.file.url;

            let e = s.split(".");
            return e.pop().toLowerCase();
        },

        preview_as_original() {
            return this.ext == "svg" || this.ext == "gif";
        },

        is_openable() {
            return (
                [
                    MediamanagerFileDisplayType.IMAGE,
                    MediamanagerFileDisplayType.PDF,
                    MediamanagerFileDisplayType.SOUND,
                    MediamanagerFileDisplayType.VIDEO
                ].indexOf(this.file.display_type) !== -1
            );
        }
    }
};
</script>
