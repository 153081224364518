<template>
    <div class="layout-messages">
        <transition-group name="layout-messages" tag="div" class="layout-messages__wrapper">
            <v-alert
                v-for="msg in messages"
                :key="msg.id"
                :type="msg.type"
            >
                <div v-if="msg.use_html == true" v-html="msg.msg"></div>
                <template v-else>{{msg.msg}}</template>
            </v-alert>
        </transition-group>
    </div>
</template>

<script>
export default {
    computed: {
        messages(){
            return this.$store.state.messages;
        }
    }
}
</script>

<style lang="scss" scoped>
.layout-messages{

    position: fixed;
    top: 24px;
    left: 50%;
    z-index: 99999;
    transform: translateX(-50%);

    padding: 0 24px;

    width: 460px;

    @media screen and (max-width: 650px){
        width: 100%;
    }

    &__wrapper{
        width: 100%;
        position: relative;
    }
}

.layout-messages-enter-active, .layout-messages-leave-active {
    transition: all .3s;
}
.layout-messages-enter, .layout-messages-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(-50px);
}
.layout-messages-leave-active {
    /* transition: transform .3s; */
    position: absolute;
}
</style>