<template>
    <div class="expressions-wizard-ifblock">
        <!-- WYBÓR RODZAJU WARUNKU -->
        <v-row
            dense
            v-if="lv === null"
        >
            <v-col
                cols="12"
                sm="6"
            >
                <div class="pa-4 rounded grey lighten-4 text-center">
                    <v-btn
                        small
                        color="primary"
                        @click="expgroup_create_dialog = true"
                        v-if="depth < nestingLimit"
                    >
                        Grupa warunków (AND/OR)
                    </v-btn>
                    <div
                        class="text-caption grey--text"
                        style="min-height: 28px; line-height: 28px"
                        v-else
                    >
                        Osiągnięto limit zagnieżdżania instrukcji warunkowych ({{ nestingLimit }})
                    </div>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
                <div class="pa-4 rounded grey lighten-4 text-center">
                    <v-btn
                        small
                        color="primary"
                        @click="setupCondition('expression')"
                    >
                        Pojedynczy warunek
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <!-- EDYCJA WYBORU + ZMIANA TYPU GRUPY -->
        <div
            v-if="lv != null"
            class="pa-2 grey lighten-4 rounded mb-n2 text-caption grey--text d-flex align-center flex-wrap"
            style="line-height: 1.2"
        >
            <div
                v-if="lv.entity_type === QuizIfConditionEntityType.EXPRESSION"
                class="mr-2"
            >
                Spełniony zostanie pojedynczy warunek:
            </div>
            <div
                v-else
                class="mr-2"
            >
                <template v-if="lv.group_type === QuizIfConditionGroupType.AND">
                    Spełnione zostaną <span class="font-weight-bold">wszystkie warunki</span> w
                    grupie:
                </template>
                <template v-else-if="lv.group_type === QuizIfConditionGroupType.OR">
                    Spełniony zostanie
                    <span class="font-weight-bold">przynajmniej jeden warunek</span> w grupie:
                </template>
            </div>
            <v-spacer></v-spacer>
            <div>
                <v-btn
                    plain
                    class="pa-0 mr-3"
                    x-small
                    color="grey"
                    style="height: auto"
                    @click="
                        () => {
                            expgroup_edit_dialog_type = lv.group_type;
                            expgroup_edit_dialog = true;
                        }
                    "
                    v-if="lv && lv.entity_type === QuizIfConditionEntityType.EXP_GROUP"
                >
                    Zmień typ grupy
                </v-btn>
                <v-btn
                    plain
                    class="pa-0"
                    x-small
                    color="grey"
                    style="height: auto"
                    @click="lv = null"
                >
                    Zmień typ warunku
                </v-btn>
            </div>
        </div>

        <div
            class="pa-2 grey lighten-4"
            style="border-radius: 4px"
            v-if="lv != null && lv.entity_type === QuizIfConditionEntityType.EXP_GROUP"
        >
            <div
                class="pa-2"
                style="border: 1px solid #dadada; border-radius: 4px; background-color: white"
            >
                <div
                    class="d-flex align-center"
                    v-for="(it, ix) in lv.group_items"
                    :key="it != null ? it.lid : ix"
                    :style="getLvGroupItemStyle(ix, lv.group_items.length)"
                >
                    <div class="flex-shrink-0 mr-2 text-caption grey--text">
                        {{ depth }}.{{ ix + 1 }}
                    </div>
                    <div class="flex-grow-1">
                        <ExpressionsWizardIfblock
                            :quiz-boards="quizBoards"
                            v-model="lv.group_items[ix]"
                            @input="emitChange"
                            ref="group_condition_wizard"
                            :depth="depth + 1"
                            :nesting-limit="nestingLimit"
                        />
                    </div>
                    <div class="flex-shrink-0 ml-2">
                        <v-btn
                            icon
                            x-small
                            color="error"
                            @click="
                                () => {
                                    lv.group_items.splice(ix, 1);
                                    emitChange();
                                }
                            "
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </div>

                <div
                    class="text-center text-caption grey--text"
                    v-if="lv.group_items.length === 0"
                >
                    Brak warunków do wyświetlenia
                </div>
            </div>
            <v-btn
                x-small
                plain
                color="primary"
                class="px-0"
                style="height: auto"
                @click="
                    () => {
                        lv.group_items.push(null);
                        emitChange();
                    }
                "
            >
                <v-icon size="10">mdi-plus</v-icon>
                Dodaj warunek
            </v-btn>
        </div>

        <ExpressionsWizardCondition
            :quiz-boards="quizBoards"
            v-model="lv"
            @input="emitChange"
            v-if="lv != null && lv.entity_type === QuizIfConditionEntityType.EXPRESSION"
            ref="solo_condition_wizard"
        />

        <!-- DIALOG WYBORU RODZAJU GRUPY WARUNKÓW (tworzenie grupy) -->
        <v-dialog
            v-model="expgroup_create_dialog"
            max-width="450"
            scrollable
        >
            <v-card>
                <v-card-title> Nowa grupa warunków </v-card-title>
                <v-divider></v-divider>
                <v-card-text
                    class="pt-5"
                    style="max-height: 70vh"
                >
                    <v-select
                        outlined
                        label="Typ grupy"
                        v-model="expgroup_create_dialog_type"
                        :items="condition_group_types_select_items"
                        hide-details="auto"
                    />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="grey lighten-1"
                        @click="expgroup_create_dialog = false"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        text
                        color="success"
                        @click="onExpGroupCreateDialogSubmit"
                    >
                        Dodaj
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- DIALOG WYBORU RODZAJU GRUPY WARUNKÓW (edycja grupy) -->
        <v-dialog
            v-model="expgroup_edit_dialog"
            max-width="450"
            scrollable
        >
            <v-card>
                <v-card-title> Nowa grupa warunków </v-card-title>
                <v-divider></v-divider>
                <v-card-text
                    class="pt-5"
                    style="max-height: 70vh"
                >
                    <v-select
                        outlined
                        label="Typ grupy"
                        v-model="expgroup_edit_dialog_type"
                        :items="condition_group_types_select_items"
                        hide-details="auto"
                    />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="grey lighten-1"
                        @click="expgroup_edit_dialog = false"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        text
                        color="success"
                        @click="
                            () => {
                                lv.group_type = expgroup_edit_dialog_type;
                                expgroup_edit_dialog = false;
                            }
                        "
                    >
                        Zastosuj
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { nanoid } from "nanoid";
import {
    QuizIfConditionEntityType,
    QuizIfConditionGroupType,
    QuizIfExpressionType
} from "../../enums/Quiz";
import ExpressionsWizardCondition from "./ExpressionsWizardCondition.vue";

export default {
    name: "ExpressionsWizardIfblock",

    props: {
        nestingLimit: {
            type: Number,
            required: true
        },
        depth: {
            type: Number,
            required: true
        },
        quizBoards: {
            type: Array,
            required: true
        },
        value: {
            type: Object
        }
    },

    data() {
        return {
            lv: null,
            expgroup_create_dialog: false,
            expgroup_create_dialog_type: QuizIfConditionGroupType.AND,

            expgroup_edit_dialog: false,
            expgroup_edit_dialog_type: QuizIfConditionGroupType.AND,

            condition_group_types_select_items: [
                {
                    text: "AND (wszystkie warunki w grupie spełnione)",
                    value: QuizIfConditionGroupType.AND
                },
                {
                    text: "OR (przynajmniej jeden warunek w grupie spełniony)",
                    value: QuizIfConditionGroupType.OR
                }
            ],

            QuizIfConditionEntityType,
            QuizIfConditionGroupType
        };
    },

    watch: {
        value: {
            handler() {
                if (this.value !== null && this.value !== undefined) {
                    this.lv = {
                        ...this.value
                    };
                } else if (this.depth >= this.nestingLimit) {
                    this.setupCondition(QuizIfConditionEntityType.EXPRESSION);
                }
            },
            immediate: true
        }
    },

    methods: {
        onExpGroupCreateDialogSubmit() {
            this.setupCondition(
                QuizIfConditionEntityType.EXP_GROUP,
                this.expgroup_create_dialog_type
            );
            this.expgroup_create_dialog = false;
        },

        getLvGroupItemStyle(ix, items_count) {
            const O = {};

            if (ix < items_count - 1) {
                O.paddingBottom = "4px";
                O.borderBottom = "1px solid #dadada";
                O.marginBottom = "4px";
            }

            return O;
        },

        setupCondition(
            entity_type = QuizIfConditionEntityType.EXPRESSION,
            group_type = QuizIfConditionGroupType.AND
        ) {
            if (entity_type === QuizIfConditionEntityType.EXPRESSION) {
                this.lv = {
                    entity_type: QuizIfConditionEntityType.EXPRESSION,
                    exp_type: QuizIfExpressionType.EQ,
                    subject: "",
                    answer: "",
                    lid: nanoid(24)
                };
            } else if (entity_type === QuizIfConditionEntityType.EXP_GROUP) {
                this.lv = {
                    entity_type: QuizIfConditionEntityType.EXP_GROUP,
                    group_type: group_type,
                    group_items: [],
                    lid: nanoid(24)
                };
            }
            this.emitChange();
        },

        validate() {
            const A = [];

            if (this.lv) {
                if (this.lv.entity_type === QuizIfConditionEntityType.EXPRESSION) {
                    A.push(this.$refs.solo_condition_wizard.validate());
                } else if (this.lv.entity_type === QuizIfConditionEntityType.EXP_GROUP) {
                    if (this.lv.group_items.length === 0) {
                        A.push(false);
                        this.$message({
                            type: "error",
                            msg: "Grupa warunków nie może być pusta"
                        });
                    } else {
                        if (this.lv.group_items.findIndex(it => it === null) === -1) {
                            if (Array.isArray(this.$refs.group_condition_wizard)) {
                                for (let i = 0; i < this.$refs.group_condition_wizard.length; i++) {
                                    A.push(this.$refs.group_condition_wizard[i].validate());
                                }
                            } else {
                                A.push(this.$refs.group_condition_wizard.validate());
                            }
                        } else {
                            A.push(false);
                            this.$message({
                                type: "error",
                                msg: "Wybierz typ dla każdego warunku w grupie"
                            });
                        }
                    }
                }
            } else {
                A.push(false);
                this.$message({
                    type: "error",
                    msg: "Wybierz typ warunku"
                });
            }

            return A.indexOf(false) === -1;
        },

        emitChange() {
            this.$emit("input", this.lv);
        }
    },
    components: { ExpressionsWizardCondition }
};
</script>
