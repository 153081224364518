import { Node, mergeAttributes } from "@tiptap/core";

const NAME = "DocumentTemplateTag";
export const DocumentTemplateTagExtension = Node.create({
    name: NAME,
    group: "inline",
    inline: true,
    selectable: true,
    atom: true,
    content: "text*",

    addOptions() {
        return {
            renderLabel({ node }) {
                return `[${node.attrs.dttname}]`;
            }
        };
    },

    addAttributes() {
        return {
            dttname: {
                default: null,
                parseHTML: element => element.getAttribute("data-dttname"),
                renderHTML: attrs => {
                    if (!attrs.dttname) {
                        return {};
                    }

                    return {
                        "data-dttname": attrs.dttname
                    };
                }
            }
        };
    },

    parseHTML() {
        return [
            {
                tag: `span[data-type="${this.name}"]`
            }
        ];
    },

    renderHTML({ node, HTMLAttributes }) {
        return [
            "span",
            mergeAttributes(
                { "data-type": this.name },
                this.options.HTMLAttributes,
                HTMLAttributes
            ),
            this.options.renderLabel({
                options: this.options,
                node
            })
        ];
    },

    renderText({ node }) {
        return this.options.renderLabel({
            options: this.options,
            node
        });
    }
});

export function insertDocumentTemplateTag(editor, dttname) {
    editor
        .chain()
        .focus()
        .insertContent([
            {
                type: NAME,
                attrs: {
                    dttname
                }
            },
            {
                type: "text",
                text: " "
            }
        ])
        .run();
}
