<template>
    <div
        class="wkmmf rounded-lg"
        :class="{
            'indigo lighten-5': selectedItemId == item._id,
            'grey lighten-4': selectedItemId != item._id
        }"
        @click="onClick"
        :title="item.name"
    >
        <div class="wkmmf__overlay"></div>
        <div class="wkmmf__content">
            <div class="wkmmf__header">
                <div class="wkmmf__icon">
                    <v-icon size="20">mdi-folder</v-icon>
                </div>
                <div class="wkmmf__name text-truncate">
                    {{ item.name }}
                </div>
                <div class="wkmmf__actions">
                    <v-btn
                        small
                        icon
                        color="secondary"
                        @contextmenu.stop.prevent
                        @click.stop="e => $nextTick(() => $emit('contextmenu-request', e))"
                    >
                        <v-icon size="20">mdi-dots-vertical</v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        selectedItemId: {
            type: String,
            default: "unknown"
        }
    },

    data() {
        return {
            ccount: 0
        };
    },

    methods: {
        selectItem() {
            this.$emit("folder-selected");
        },

        onClick() {
            this.ccount += 1;
            setTimeout(() => {
                this.ccount = 0;
            }, 300);

            if (this.ccount >= 2) {
                this.$emit("open-folder");
                this.ccount = 0;
            } else {
                this.selectItem();
            }
        }
    }
};
</script>
