export const HelpdeskMessageType = Object.freeze({
    PLAINTEXT: "plaintext",
    HTML: "html",
    EVENT: "event"
});

export const HelpdeskMessageAuthorType = Object.freeze({
    USER_CLIENT: "user_client",
    USER_STAFF: "user_staff",
    SYSTEM: "system"
});

export const HelpdeskMessageEventName = Object.freeze({
    THREAD_CLOSED: "thread_closed"
});

export const HelpdeskMessageStatus = Object.freeze({
    CREATED: "created",
    SENT: "sent",
    DELIVERED: "delivered",
    READ: "read"
});
