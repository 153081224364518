export const QuizBoardType = Object.freeze({
    QUESTION_SINGLE_CHOICE: "question_single_choice",
    QUESTION_MULTIPLE_CHOICE: "question_multiple_choice",
    QUESTION_OPEN: "question_open",
    QUESTION_NPS: "question_nps",
    QUESTION_RANGE_SLIDER: "question_range_slider",
    QUESTION_FILE: "question_file",
    CUSTOMER_CONTACT_DATA_FORM: "customer_contact_data_form",
    CUSTOMER_PHONE_NUMBER: "customer_phone_number",
    STANDALONE_CHECKOUT_SESSION: "standalone_checkout_session",
    CHECKOUT_SESSION: "checkout_session",
    RISIFY_AUTOAUTH: "risify_autoauth",
    STATIC_THERAPIST_RECOMMENDATION: "static_therapist_recommendation",
    STATIC_CONTENT: "static_content"
});
export const QuizBoardTypeLabel = Object.freeze({
    [QuizBoardType.QUESTION_SINGLE_CHOICE]: "Pytanie jednokrotnego wyboru",
    [QuizBoardType.QUESTION_MULTIPLE_CHOICE]: "Pytanie wielokrotnego wyboru",
    [QuizBoardType.QUESTION_OPEN]: "Pytanie otwarte",
    [QuizBoardType.QUESTION_NPS]: "Ocena w skali 1-10",
    [QuizBoardType.QUESTION_RANGE_SLIDER]: "Liczba z zakresu",
    [QuizBoardType.QUESTION_FILE]: "Pliki do przesłania",
    [QuizBoardType.CUSTOMER_CONTACT_DATA_FORM]: "Formularz danych kontaktowych klienta",
    [QuizBoardType.CUSTOMER_PHONE_NUMBER]: "Numer telefonu klienta",
    [QuizBoardType.STANDALONE_CHECKOUT_SESSION]: "Standalone Checkout Session",
    [QuizBoardType.CHECKOUT_SESSION]: "Checkout Session",
    [QuizBoardType.RISIFY_AUTOAUTH]: "Automatyczna rejestracja i logowanie w Risify",
    [QuizBoardType.STATIC_THERAPIST_RECOMMENDATION]: "Statyczna rekomendacja terapeutów",
    [QuizBoardType.STATIC_CONTENT]: "Treść statyczna"
});
export const QuizBoardTypeSelectItems = Object.values(QuizBoardType).map(it => {
    return {
        text: QuizBoardTypeLabel[it],
        value: it
    };
});

export const QuizBoardPersonalizationTag = Object.freeze({
    IMIE: "IMIE",
    IMIE_WOLACZ: "IMIE_WOLACZ"
});
export const QuizBoardPersonalizationTagLabel = Object.freeze({
    [QuizBoardPersonalizationTag.IMIE]: "Imię",
    [QuizBoardPersonalizationTag.IMIE_WOLACZ]: "Imię (w wołaczu)"
});
export const QuizBoardPersonalizationTagSelectItems = Object.values(
    QuizBoardPersonalizationTag
).map(it => {
    return {
        text: QuizBoardPersonalizationTagLabel[it],
        value: it
    };
});

export const QuizBoardQuestionOpenInputType = Object.freeze({
    TEXTFIELD: "textfield",
    TEXTAREA: "textarea"
});
export const QuizBoardQuestionOpenInputTypeLabel = Object.freeze({
    [QuizBoardQuestionOpenInputType.TEXTFIELD]: "Pole tekstowe",
    [QuizBoardQuestionOpenInputType.TEXTAREA]: "Obszar tekstowy"
});
export const QuizBoardQuestionOpenInputTypeSelectItems = Object.values(
    QuizBoardQuestionOpenInputType
).map(it => {
    return {
        text: QuizBoardQuestionOpenInputTypeLabel[it],
        value: it
    };
});

export const QuizBoardQuestionOpenValidationRule = Object.freeze({
    NONE: "none",
    EMAIL: "email",
    YEAR: "year",
    POSITIVE_INT: "positive_int",
    FULL_NAME: "full_name"
});
export const QuizBoardQuestionOpenValidationRuleLabel = Object.freeze({
    [QuizBoardQuestionOpenValidationRule.NONE]: "Brak",
    [QuizBoardQuestionOpenValidationRule.EMAIL]: "Adres e-mail",
    [QuizBoardQuestionOpenValidationRule.YEAR]: "Rok",
    [QuizBoardQuestionOpenValidationRule.POSITIVE_INT]: "Dodatnia liczba całkowita",
    [QuizBoardQuestionOpenValidationRule.FULL_NAME]: "Imię i nazwisko"
});
export const QuizBoardQuestionOpenValidationRuleSelectItems = Object.values(
    QuizBoardQuestionOpenValidationRule
).map(it => {
    return {
        text: QuizBoardQuestionOpenValidationRuleLabel[it],
        value: it
    };
});

export const QuizBoardQuestionSingleChoiceAnswersLayout = Object.freeze({
    LIST: "list",
    IMAGE_TILES: "image_tiles",
    EMOJIS_GROUP: "emojis_group",
    GRID_2COL: "grid_2col",
    GRID_3COL: "grid_3col",
    GRID_4COL: "grid_4col"
});
export const QuizBoardQuestionSingleChoiceAnswersLayoutLabel = Object.freeze({
    [QuizBoardQuestionSingleChoiceAnswersLayout.LIST]: "Lista",
    [QuizBoardQuestionSingleChoiceAnswersLayout.IMAGE_TILES]: "Kafelki obrazów",
    [QuizBoardQuestionSingleChoiceAnswersLayout.EMOJIS_GROUP]: "Grupa emotikonów",
    [QuizBoardQuestionSingleChoiceAnswersLayout.GRID_2COL]: "Siatka 2-kolumnowa",
    [QuizBoardQuestionSingleChoiceAnswersLayout.GRID_3COL]: "Siatka 3-kolumnowa",
    [QuizBoardQuestionSingleChoiceAnswersLayout.GRID_4COL]: "Siatka 4-kolumnowa"
});
export const QuizBoardQuestionSingleChoiceAnswersLayoutIcon = Object.freeze({
    [QuizBoardQuestionSingleChoiceAnswersLayout.LIST]: "mdi-view-list",
    [QuizBoardQuestionSingleChoiceAnswersLayout.IMAGE_TILES]: "mdi-view-grid",
    [QuizBoardQuestionSingleChoiceAnswersLayout.EMOJIS_GROUP]: "mdi-view-carousel",
    [QuizBoardQuestionSingleChoiceAnswersLayout.GRID_2COL]: "mdi-grid",
    [QuizBoardQuestionSingleChoiceAnswersLayout.GRID_3COL]: "mdi-grid",
    [QuizBoardQuestionSingleChoiceAnswersLayout.GRID_4COL]: "mdi-grid"
});
export const QuizBoardQuestionSingleChoiceAnswersLayoutSelectItems = Object.values(
    QuizBoardQuestionSingleChoiceAnswersLayout
).map(it => {
    return {
        text: QuizBoardQuestionSingleChoiceAnswersLayoutLabel[it],
        value: it,
        icon: QuizBoardQuestionSingleChoiceAnswersLayoutIcon[it]
    };
});

export const QuizBoardQuestionMultipleChoiceAnswersLayout = Object.freeze({
    LIST: "list",
    CLOUD: "cloud"
});
export const QuizBoardQuestionMultipleChoiceAnswersLayoutLabel = Object.freeze({
    [QuizBoardQuestionMultipleChoiceAnswersLayout.LIST]: "Lista",
    [QuizBoardQuestionMultipleChoiceAnswersLayout.CLOUD]: "Chmurki"
});
export const QuizBoardQuestionMultipleChoiceAnswersLayoutIcon = Object.freeze({
    [QuizBoardQuestionMultipleChoiceAnswersLayout.LIST]: "mdi-view-list",
    [QuizBoardQuestionMultipleChoiceAnswersLayout.CLOUD]: "mdi-subtitles"
});
export const QuizBoardQuestionMultipleChoiceAnswersLayoutSelectItems = Object.values(
    QuizBoardQuestionMultipleChoiceAnswersLayout
).map(it => {
    return {
        text: QuizBoardQuestionMultipleChoiceAnswersLayoutLabel[it],
        value: it,
        icon: QuizBoardQuestionMultipleChoiceAnswersLayoutIcon[it]
    };
});

export const QuizBoardStandaloneCheckoutSessionLayout = Object.freeze({
    L1: "l1"
});
export const QuizBoardStandaloneCheckoutSessionLayoutLabel = Object.freeze({
    [QuizBoardStandaloneCheckoutSessionLayout.L1]: "Domyślny"
});
export const QuizBoardStandaloneCheckoutSessionLayoutSelectItems = Object.values(
    QuizBoardStandaloneCheckoutSessionLayout
).map(it => {
    return {
        text: QuizBoardStandaloneCheckoutSessionLayoutLabel[it],
        value: it
    };
});

export const QuizBoardStandaloneCheckoutSessionCurrency = Object.freeze({
    PLN: "pln",
    EUR: "eur"
});
export const QuizBoardStandaloneCheckoutSessionCurrencyLabel = Object.freeze({
    [QuizBoardStandaloneCheckoutSessionCurrency.PLN]: "PLN (zł)",
    [QuizBoardStandaloneCheckoutSessionCurrency.EUR]: "EUR (€)"
});
export const QuizBoardStandaloneCheckoutSessionCurrencySelectItems = Object.values(
    QuizBoardStandaloneCheckoutSessionCurrency
).map(it => {
    return {
        text: QuizBoardStandaloneCheckoutSessionCurrencyLabel[it],
        value: it
    };
});

export const QuizBoardCheckoutSessionLayout = Object.freeze({
    L1: "l1",
    L2_RIS_LEGACY: "l2_rl"
});
export const QuizBoardCheckoutSessionLayoutLabel = Object.freeze({
    [QuizBoardCheckoutSessionLayout.L1]: "Domyślny (L1)",
    [QuizBoardCheckoutSessionLayout.L2_RIS_LEGACY]: "Wzorujący się na portal.risify.pl (L2_RL)"
});
export const QuizBoardCheckoutSessionLayoutSelectItems = Object.values(
    QuizBoardCheckoutSessionLayout
).map(it => {
    return {
        text: QuizBoardCheckoutSessionLayoutLabel[it],
        value: it
    };
});

export const QuizBoardStaticTherapistRecommendationListMode = Object.freeze({
    STATIC: "static",
    RANDOM: "random"
});
export const QuizBoardStaticTherapistRecommendationListModeLabel = Object.freeze({
    [QuizBoardStaticTherapistRecommendationListMode.STATIC]: "Statyczne",
    [QuizBoardStaticTherapistRecommendationListMode.RANDOM]: "Losowe"
});
export const QuizBoardStaticTherapistRecommendationListModeSelectItems = Object.values(
    QuizBoardStaticTherapistRecommendationListMode
).map(it => {
    return {
        text: QuizBoardStaticTherapistRecommendationListModeLabel[it],
        value: it
    };
});
