<template>
    <v-dialog
        v-model="show"
        :fullscreen="fullscreen_mode"
        max-width="960"
        scrollable
        class="wkmm"
        ref="dialog"
    >
        <v-card>
            <v-card-title ref="dialog_title">
                <div class="wkmm-title">
                    <div class="wkmm-title__content">
                        <div class="wkmm-title__topline">
                            <v-btn
                                icon
                                title="Wróć do folderu głównego"
                                color="secondary"
                                :disabled="current_folder == null"
                                small
                                @click="openFolder(null)"
                            >
                                <v-icon size="20">mdi-home</v-icon>
                            </v-btn>
                            <h2 class="text-h5 font-weight-medium wkmm-title__tname">
                                Media Manager
                            </h2>
                        </div>
                        <div class="wkmm-title__bottomline text-body-1">
                            <v-breadcrumbs
                                :items="breadcrumb_items"
                                large
                            >
                                <template v-slot:divider>
                                    <v-icon size="20">mdi-chevron-right</v-icon>
                                </template>
                                <template v-slot:item="{ item }">
                                    <li
                                        class="v-breadcrumbs__item"
                                        :title="item.name"
                                    >
                                        <v-btn
                                            rounded
                                            color="grey darken-2"
                                            text
                                            small
                                            style="text-transform: none"
                                            class="text-body-2"
                                            @click="openFolder(item._id)"
                                        >
                                            {{ item.name }}
                                        </v-btn>
                                    </li>
                                </template>
                            </v-breadcrumbs>

                            <v-btn
                                icon
                                title="Utwórz folder"
                                color="secondary"
                                @click.stop="folder_create_dialog = true"
                                :disabled="!inited"
                                small
                            >
                                <v-icon size="20">mdi-folder-plus</v-icon>
                            </v-btn>
                            <v-btn
                                icon
                                title="Wgraj plik(i)"
                                color="secondary"
                                @click.stop="
                                    () => {
                                        files_uploader_target = current_folder;
                                        files_uploader = true;
                                    }
                                "
                                :disabled="!inited"
                                small
                            >
                                <v-icon size="20">mdi-file-upload</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <div class="wkmm-title__actions">
                        <v-btn
                            icon
                            title="Zamknij okno"
                            color="secondary"
                            @click="close"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text
                :style="{ 'max-height': content_max_height }"
                class="pt-5"
            >
                <v-row>
                    <v-col
                        cols="12"
                        md="8"
                    >
                        <div
                            class="wkmm__maincontent"
                            @contextmenu="showGeneralContextMenu"
                        >
                            <v-fade-transition mode="out-in">
                                <!-- GENERAL LOADING STATE -->
                                <v-sheet
                                    class="wkmm__mainloader"
                                    rounded="lg"
                                    v-if="opening_folder"
                                >
                                    <v-skeleton-loader type="heading"></v-skeleton-loader>
                                    <v-skeleton-loader
                                        type="date-picker-days"
                                        class="mt-2"
                                    ></v-skeleton-loader>
                                    <v-skeleton-loader
                                        type="heading"
                                        class="mt-8"
                                    ></v-skeleton-loader>
                                    <v-skeleton-loader
                                        type="date-picker-days"
                                        class="mt-2"
                                    ></v-skeleton-loader>
                                </v-sheet>
                                <!-- LOADED CONTENT -->
                                <div v-else>
                                    <div
                                        v-if="folders_loaded && folders.length > 0"
                                        class="mb-8"
                                    >
                                        <h4 class="text-body-2 text-uppercase grey--text mb-2">
                                            Foldery
                                        </h4>
                                        <div class="wkmm-igrid">
                                            <div
                                                class="wkmm-igrid__item"
                                                v-for="folder in folders"
                                                :key="folder._id"
                                            >
                                                <WkMediaManagerFolder
                                                    :item="folder"
                                                    :selectedItemId="
                                                        selected_object != null &&
                                                        selected_object.object_type == 'folder'
                                                            ? selected_object.object_data._id
                                                            : 'none'
                                                    "
                                                    @folder-selected="
                                                        selectObject('folder', folder)
                                                    "
                                                    @contextmenu-request="
                                                        e => {
                                                            folder_ctx_menu_folder = folder;
                                                            hideAllCtxMenus();
                                                            timeout(() => {
                                                                showFolderContextMenu(e);
                                                            }, 1);
                                                        }
                                                    "
                                                    @contextmenu.native.stop="
                                                        e => {
                                                            folder_ctx_menu_folder = folder;
                                                            showFolderContextMenu(e);
                                                        }
                                                    "
                                                    @open-folder="openFolder(folder._id)"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            style="height: 1px"
                                            v-intersect="folderIoCb"
                                        ></div>
                                        <div
                                            class="py-2 text-center"
                                            v-if="folders_loading"
                                        >
                                            <v-progress-circular
                                                indeterminate
                                                color="primary"
                                                size="24"
                                                width="2"
                                            ></v-progress-circular>
                                        </div>
                                    </div>
                                    <h4 class="text-body-2 text-uppercase grey--text mb-2">
                                        Pliki
                                    </h4>
                                    <div class="wkmm-igrid">
                                        <div
                                            class="wkmm-igrid__item"
                                            v-for="file in files"
                                            :key="file._id"
                                        >
                                            <WkMediaManagerFile
                                                :item="file"
                                                :selectedItemId="
                                                    selected_object != null &&
                                                    selected_object.object_type == 'file'
                                                        ? selected_object.object_data._id
                                                        : 'none'
                                                "
                                                @file-selected="selectObject('file', file)"
                                                @contextmenu-request="
                                                    e => {
                                                        file_ctx_menu_file = file;
                                                        hideAllCtxMenus();
                                                        timeout(() => {
                                                            showFileContextMenu(e);
                                                        }, 1);
                                                    }
                                                "
                                                @contextmenu.native.stop="
                                                    e => {
                                                        file_ctx_menu_file = file;
                                                        showFileContextMenu(e);
                                                    }
                                                "
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="rounded grey--text text-caption"
                                        v-if="files.length == 0 && files_loaded"
                                    >
                                        Nie wgrano jeszcze żadnego pliku
                                    </div>
                                    <div
                                        style="height: 1px"
                                        v-intersect="filesIoCb"
                                    ></div>
                                    <div
                                        class="py-2 text-center"
                                        v-if="files_loading"
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="primary"
                                            size="24"
                                            width="2"
                                        ></v-progress-circular>
                                    </div>
                                </div>
                            </v-fade-transition>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        md="4"
                    >
                        <v-sheet
                            rounded="lg"
                            color="grey lighten-4 pa-3"
                            flat
                            class="wkmm-finfo"
                            v-if="!fullscreen_mode || finfo_opened"
                            :style="{
                                '--finfo-offset': finfo_offset,
                                '--finfo-height': finfo_height
                            }"
                        >
                            <template v-if="selected_object != null">
                                <div v-if="selected_object.object_type == 'folder'">
                                    <h4 class="text-body-1 font-weight-medium d-flex">
                                        <span>Szczegóły folderu</span>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            icon
                                            small
                                            color="secondary"
                                            @click="finfo_opened = false"
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </h4>
                                    <div class="text-center py-4">
                                        <v-icon size="96">mdi-folder</v-icon>
                                    </div>
                                    <div class="wkmm-detlist">
                                        <div class="wkmm-detlist__item">
                                            <div class="wkmm-detlist__detname">
                                                Nazwa:
                                                <v-btn
                                                    x-small
                                                    icon
                                                    color="grey"
                                                    @click.stop="
                                                        () => {
                                                            folder_ne_dialog = true;
                                                            folder_ne_dialog_item = JSON.parse(
                                                                JSON.stringify(
                                                                    selected_object.object_data
                                                                )
                                                            );
                                                        }
                                                    "
                                                >
                                                    <v-icon size="16">mdi-pencil</v-icon>
                                                </v-btn>
                                            </div>
                                            <div class="wkmm-detlist__detval font-weight-bold">
                                                {{ selected_object.object_data.name }}
                                            </div>
                                        </div>
                                        <div class="wkmm-detlist__item">
                                            <div class="wkmm-detlist__detname">
                                                Liczba podfolderów:
                                            </div>
                                            <div class="wkmm-detlist__detval font-weight-bold">
                                                {{ selected_object.object_data.subfolders_count }}
                                            </div>
                                        </div>
                                        <div class="wkmm-detlist__item">
                                            <div class="wkmm-detlist__detname">Liczba plików:</div>
                                            <div class="wkmm-detlist__detval font-weight-bold">
                                                {{ selected_object.object_data.files_count }}
                                            </div>
                                        </div>
                                        <div class="wkmm-detlist__item">
                                            <div class="wkmm-detlist__detname">Utworzono:</div>
                                            <div class="wkmm-detlist__detval font-weight-bold">
                                                {{
                                                    new Date(
                                                        selected_object.object_data.c_date
                                                    ).toLocaleString()
                                                }}
                                            </div>
                                        </div>
                                        <div class="wkmm-detlist__item">
                                            <div class="wkmm-detlist__detname">Zmodyfikowano:</div>
                                            <div class="wkmm-detlist__detval font-weight-bold">
                                                {{
                                                    new Date(
                                                        selected_object.object_data.m_date
                                                    ).toLocaleString()
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="selected_object.object_type == 'file'">
                                    <h4 class="text-body-1 font-weight-medium d-flex">
                                        <span>Szczegóły pliku</span>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            icon
                                            small
                                            color="secondary"
                                            @click="finfo_opened = false"
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </h4>
                                    <div class="my-4">
                                        <WkMediaManagerFilePreview
                                            :file="selected_object.object_data"
                                        />
                                    </div>
                                    <div class="wkmm-detlist">
                                        <div class="wkmm-detlist__item">
                                            <div class="wkmm-detlist__detname">
                                                Nazwa:
                                                <v-btn
                                                    x-small
                                                    icon
                                                    color="grey"
                                                    @click.stop="
                                                        () => {
                                                            file_ne_dialog = true;
                                                            file_ne_dialog_item = JSON.parse(
                                                                JSON.stringify(
                                                                    selected_object.object_data
                                                                )
                                                            );
                                                        }
                                                    "
                                                >
                                                    <v-icon size="16">mdi-pencil</v-icon>
                                                </v-btn>
                                            </div>
                                            <div class="wkmm-detlist__detval font-weight-bold">
                                                {{ selected_object.object_data.name }}
                                            </div>
                                        </div>
                                        <!-- 
                                        <div class="wkmm-detlist__item">
                                            <div class="wkmm-detlist__detname">
                                                URL:
                                                <v-btn
                                                    x-small
                                                    icon
                                                    color="grey"
                                                    @click.stop="
                                                        () => {
                                                            file_une_dialog = true;
                                                            file_une_dialog_item = JSON.parse(
                                                                JSON.stringify(
                                                                    selected_object.object_data
                                                                )
                                                            );
                                                            file_une_dialog_value =
                                                                selected_object.object_data.url_name.slice(
                                                                    9
                                                                );
                                                        }
                                                    "
                                                >
                                                    <v-icon size="16">mdi-pencil</v-icon>
                                                </v-btn>
                                            </div>
                                            <div class="wkmm-detlist__detval font-weight-bold">
                                                <span class="grey--text">
                                                    {{
                                                        selected_object.object_data.url_name.slice(
                                                            0,
                                                            9
                                                        )
                                                    }}</span
                                                >{{ selected_object.object_data.url_name.slice(9) }}
                                            </div>
                                        </div>
                                        -->
                                        <div class="wkmm-detlist__item">
                                            <div class="wkmm-detlist__detname">Rozmiar:</div>
                                            <div class="wkmm-detlist__detval font-weight-bold">
                                                {{
                                                    formatFileSize(selected_object.object_data.size)
                                                }}
                                            </div>
                                        </div>
                                        <WkMediaManagerFileMetadataDetlist
                                            :metadata="selected_object.object_data.metadata"
                                        >
                                            <template v-slot:item="{ item }">
                                                <div class="wkmm-detlist__item">
                                                    <div class="wkmm-detlist__detname">
                                                        {{ item.name }}:
                                                    </div>
                                                    <div
                                                        class="wkmm-detlist__detval font-weight-bold"
                                                    >
                                                        {{ item.value }}
                                                    </div>
                                                </div>
                                            </template>
                                        </WkMediaManagerFileMetadataDetlist>
                                        <div class="wkmm-detlist__item">
                                            <div class="wkmm-detlist__detname">Utworzono:</div>
                                            <div class="wkmm-detlist__detval font-weight-bold">
                                                {{
                                                    new Date(
                                                        selected_object.object_data.c_date
                                                    ).toLocaleString()
                                                }}
                                            </div>
                                        </div>
                                        <div class="wkmm-detlist__item">
                                            <div class="wkmm-detlist__detname">Zmodyfikowano:</div>
                                            <div class="wkmm-detlist__detval font-weight-bold">
                                                {{
                                                    new Date(
                                                        selected_object.object_data.m_date
                                                    ).toLocaleString()
                                                }}
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="mt-2"
                                        v-if="fileSelect"
                                    >
                                        <v-btn
                                            small
                                            color="primary"
                                            block
                                            @click="
                                                $emit('file', selected_object.object_data);
                                                finfo_opened = false;
                                            "
                                        >
                                            Wybierz plik
                                        </v-btn>
                                    </div>
                                </div>
                            </template>
                            <div
                                class="wkmm-finfo__unselected"
                                v-else
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    data-name="Layer 1"
                                    width="647.63626"
                                    height="632.17383"
                                    viewBox="0 0 647.63626 632.17383"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                >
                                    <path
                                        d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z"
                                        transform="translate(-276.18187 -133.91309)"
                                        fill="#f2f2f2"
                                    />
                                    <path
                                        d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z"
                                        transform="translate(-276.18187 -133.91309)"
                                        fill="#3f3d56"
                                    />
                                    <path
                                        d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z"
                                        transform="translate(-276.18187 -133.91309)"
                                        fill="#2a81c1"
                                    />
                                    <circle
                                        cx="190.15351"
                                        cy="24.95465"
                                        r="20"
                                        fill="#2a81c1"
                                    />
                                    <circle
                                        cx="190.15351"
                                        cy="24.95465"
                                        r="12.66462"
                                        fill="#fff"
                                    />
                                    <path
                                        d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z"
                                        transform="translate(-276.18187 -133.91309)"
                                        fill="#e6e6e6"
                                    />
                                    <path
                                        d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z"
                                        transform="translate(-276.18187 -133.91309)"
                                        fill="#3f3d56"
                                    />
                                    <path
                                        d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z"
                                        transform="translate(-276.18187 -133.91309)"
                                        fill="#2a81c1"
                                    />
                                    <circle
                                        cx="433.63626"
                                        cy="105.17383"
                                        r="20"
                                        fill="#2a81c1"
                                    />
                                    <circle
                                        cx="433.63626"
                                        cy="105.17383"
                                        r="12.18187"
                                        fill="#fff"
                                    />
                                </svg>
                                <div class="wkmm-finfo__unseltext text-body-2 text-center mt-4">
                                    Aby wyświetlić szczegóły, wybierz plik lub folder.
                                </div>
                            </div>
                        </v-sheet>
                    </v-col>
                </v-row>

                <!-- CONTEXT MENU (OGÓLNE) -->
                <v-menu
                    v-model="general_ctx_menu"
                    :position-x="general_ctx_menu_x"
                    :position-y="general_ctx_menu_y"
                    absolute
                    offset-y
                    transition="slide-y-transition"
                >
                    <div
                        class="white wkmm-ctxmenu"
                        @contextmenu.prevent
                    >
                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="folder_create_dialog = true"
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-folder-plus</v-icon
                            >
                            Nowy folder
                        </v-btn>
                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="
                                () => {
                                    files_uploader_target = current_folder;
                                    files_uploader = true;
                                }
                            "
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-file-upload</v-icon
                            >
                            Wgraj plik(i)
                        </v-btn>
                    </div>
                </v-menu>
                <!-- CONTEXT MENU (EDYCJA FOLDERU) -->
                <v-menu
                    v-model="folder_ctx_menu"
                    :position-x="folder_ctx_menu_x"
                    :position-y="folder_ctx_menu_y"
                    absolute
                    offset-y
                    transition="slide-y-transition"
                >
                    <div
                        class="white wkmm-ctxmenu"
                        @contextmenu.prevent
                    >
                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="openFolder(folder_ctx_menu_folder._id)"
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-folder-open</v-icon
                            >
                            Otwórz
                        </v-btn>
                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="
                                () => {
                                    files_uploader_target = folder_ctx_menu_folder;
                                    files_uploader = true;
                                }
                            "
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-file-upload</v-icon
                            >
                            Wgraj plik(i)
                        </v-btn>
                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="selectObject('folder', folder_ctx_menu_folder)"
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-information-outline</v-icon
                            >
                            Wyświetl szczegóły
                        </v-btn>
                        <v-divider></v-divider>

                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="
                                () => {
                                    folder_ne_dialog = true;
                                    folder_ne_dialog_item = JSON.parse(
                                        JSON.stringify(folder_ctx_menu_folder)
                                    );
                                }
                            "
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-folder-edit</v-icon
                            >
                            Zmień nazwę
                        </v-btn>
                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="
                                () => {
                                    folder_select_dialog_mode = 'folder';
                                    folder_select_dialog_current_folder = current_folder
                                        ? current_folder
                                        : { _id: null, name: 'Folder główny' };
                                    folder_select_dialog_subject = folder_ctx_menu_folder.name;
                                    folder_select_dialog_subject_id = folder_ctx_menu_folder._id;
                                    folder_select_dialog = true;
                                }
                            "
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-folder-move</v-icon
                            >
                            Przenieś do folderu
                        </v-btn>
                        <v-divider></v-divider>
                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="
                                () => {
                                    folder_delete_dialog = true;
                                    folder_delete_dialog_id = folder_ctx_menu_folder._id;
                                    folder_delete_dialog_name = folder_ctx_menu_folder.name;
                                }
                            "
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-trash-can</v-icon
                            >
                            Usuń folder
                        </v-btn>
                    </div>
                </v-menu>
                <!-- CONTEXT MENU (EDYCJA PLIKU) -->
                <v-menu
                    v-model="file_ctx_menu"
                    :position-x="file_ctx_menu_x"
                    :position-y="file_ctx_menu_y"
                    absolute
                    offset-y
                    transition="slide-y-transition"
                >
                    <div
                        class="white wkmm-ctxmenu"
                        @contextmenu.prevent
                    >
                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="copyFileLink(file_ctx_menu_file)"
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-link</v-icon
                            >
                            Kopiuj link
                        </v-btn>
                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="
                                () => {
                                    file_ne_dialog = true;
                                    file_ne_dialog_item = JSON.parse(
                                        JSON.stringify(file_ctx_menu_file)
                                    );
                                }
                            "
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-folder-edit</v-icon
                            >
                            Zmień nazwę
                        </v-btn>
                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="
                                () => {
                                    folder_select_dialog_mode = 'file';
                                    folder_select_dialog_current_folder = current_folder
                                        ? current_folder
                                        : { _id: null, name: 'Folder główny' };
                                    folder_select_dialog_subject = file_ctx_menu_file.name;
                                    folder_select_dialog_subject_id = file_ctx_menu_file._id;
                                    folder_select_dialog = true;
                                }
                            "
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-folder-move</v-icon
                            >
                            Przenieś do folderu
                        </v-btn>

                        <v-divider></v-divider>
                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="selectObject('file', file_ctx_menu_file)"
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-information-outline</v-icon
                            >
                            Wyświetl szczegóły
                        </v-btn>
                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="downloadFile(file_ctx_menu_file)"
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-download</v-icon
                            >
                            Pobierz plik
                        </v-btn>

                        <v-divider></v-divider>
                        <v-btn
                            text
                            color="grey darken-2"
                            large
                            style="text-transform: none"
                            class="font-weight-regular text-body-2"
                            @click="
                                () => {
                                    file_delete_dialog = true;
                                    file_delete_dialog_id = file_ctx_menu_file._id;
                                    file_delete_dialog_name = file_ctx_menu_file.name;
                                }
                            "
                        >
                            <v-icon
                                class="mr-2"
                                color="grey darken-1"
                                >mdi-trash-can</v-icon
                            >
                            Usuń plik
                        </v-btn>
                    </div>
                </v-menu>

                <!-- FOLDER CREATE DIALOG -->
                <v-dialog
                    v-model="folder_create_dialog"
                    persistent
                    max-width="450"
                    scrollable
                >
                    <v-card>
                        <v-card-title> Utwórz folder </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text
                            class="pt-5"
                            style="max-height: 70vh"
                        >
                            <v-text-field
                                outlined
                                label="Nazwa folderu"
                                v-model="folder_create_dialog_name"
                                ref="folder_create_dialog_name"
                                hide-details="auto"
                                :rules="[validatorRequired, validatorFolderName]"
                                @keydown.enter="createFolder"
                            ></v-text-field>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="grey lighten-1"
                                :disabled="folder_create_dialog_loading"
                                @click="folder_create_dialog = false"
                            >
                                Anuluj
                            </v-btn>
                            <v-btn
                                text
                                color="success"
                                :loading="folder_create_dialog_loading"
                                @click="createFolder"
                            >
                                Utwórz
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- FOLDER NAME EDIT DIALOG -->
                <v-dialog
                    v-model="folder_ne_dialog"
                    persistent
                    max-width="450"
                    scrollable
                >
                    <v-card>
                        <v-card-title> Zmień nazwę folderu </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text
                            class="pt-5"
                            style="max-height: 70vh"
                            v-if="folder_ne_dialog_item"
                        >
                            <v-text-field
                                outlined
                                label="Nazwa folderu"
                                v-model="folder_ne_dialog_item.name"
                                ref="folder_ne_dialog_name"
                                hide-details="auto"
                                :rules="[validatorRequired, validatorFolderName]"
                                @keydown.enter="editFolderName"
                            ></v-text-field>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="grey lighten-1"
                                :disabled="folder_ne_dialog_loading"
                                @click="folder_ne_dialog = false"
                            >
                                Anuluj
                            </v-btn>
                            <v-btn
                                text
                                color="success"
                                :loading="folder_ne_dialog_loading"
                                @click="editFolderName"
                            >
                                Zapisz
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- FOLDER DELETE DIALOG -->
                <v-dialog
                    v-model="folder_delete_dialog"
                    persistent
                    max-width="400"
                    scrollable
                >
                    <v-card>
                        <v-card-title> Usuń folder </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text
                            class="pt-5"
                            style="max-height: 70vh"
                        >
                            Czy na pewno usunąć folder <b>{{ folder_delete_dialog_name }}</b
                            >?
                            <br />
                            <b>UWAGA!</b> Usuniesz również wszystkie pliki oraz subfoldery, które
                            zawiera!
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="grey lighten-1"
                                :disabled="folder_delete_dialog_loading"
                                @click="folder_delete_dialog = false"
                            >
                                Anuluj
                            </v-btn>
                            <v-btn
                                text
                                color="error"
                                :loading="folder_delete_dialog_loading"
                                @click="deleteFolder"
                            >
                                Usuń
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- FOLDER CHANGE DIALOG -->
                <WkMediaManagerFolderSelect
                    v-model="folder_select_dialog"
                    :key="
                        folder_select_dialog_mode +
                        folder_select_dialog_subject_id +
                        folder_select_dialog_subject
                    "
                    :subject-name="folder_select_dialog_subject"
                    :subject-id="
                        folder_select_dialog_mode == 'folder' ? folder_select_dialog_subject_id : ''
                    "
                    :current-folder="folder_select_dialog_current_folder"
                    :loading="folder_select_dialog_loading"
                    @folder-selected="onFolderSelectDialogSubmit"
                />

                <!-- FILES UPLOADER -->
                <WkMediaManagerFilesUploader
                    v-model="files_uploader"
                    :target="files_uploader_target"
                    @file-uploaded="onNewFileUploaded"
                />

                <!-- FILE NAME EDIT DIALOG -->
                <v-dialog
                    v-model="file_ne_dialog"
                    persistent
                    max-width="450"
                    scrollable
                >
                    <v-card>
                        <v-card-title>
                            <div>
                                Zmień nazwę pliku
                                <div class="text-caption grey--text mt-n1">
                                    Nazwa pliku pełni funkcję organizacyjną
                                </div>
                            </div>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text
                            class="pt-5"
                            style="max-height: 70vh"
                            v-if="file_ne_dialog_item"
                        >
                            <v-text-field
                                outlined
                                label="Nazwa pliku"
                                v-model="file_ne_dialog_item.name"
                                ref="file_ne_dialog_name"
                                hide-details="auto"
                                :rules="[validatorRequired, validatorFileName]"
                                @keydown.enter="editFileName"
                            ></v-text-field>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="grey lighten-1"
                                :disabled="file_ne_dialog_loading"
                                @click="file_ne_dialog = false"
                            >
                                Anuluj
                            </v-btn>
                            <v-btn
                                text
                                color="success"
                                :loading="file_ne_dialog_loading"
                                @click="editFileName"
                            >
                                Zapisz
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- FILE URL NAME EDIT DIALOG -->
                <v-dialog
                    v-model="file_une_dialog"
                    persistent
                    max-width="450"
                    scrollable
                >
                    <v-card>
                        <v-card-title> Zmień ścieżkę do pliku w URL </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text
                            class="pt-5"
                            style="max-height: 70vh"
                            v-if="file_une_dialog_item"
                        >
                            <v-alert
                                type="warning"
                                dense
                                prominent
                                class="mb-6"
                                text
                            >
                                <div
                                    class="text-caption"
                                    style="line-height: 1.4"
                                >
                                    UWAGA! Zmiana ścieżki w URL spowoduje, że plik przestanie się
                                    poprawnie wyświetlać we wszystkich miejscach, w których
                                    wcześniej został już umieszczony!
                                </div>
                            </v-alert>
                            <v-text-field
                                outlined
                                label="Ścieżka do pliku"
                                v-model="file_une_dialog_value"
                                ref="file_une_dialog_value"
                                hide-details="auto"
                                :rules="[validatorRequired, validatorFileUrlName]"
                                @keydown.enter="editFileUrlName"
                                :prefix="file_une_dialog_item.url_name.slice(0, 9)"
                            ></v-text-field>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="grey lighten-1"
                                :disabled="file_une_dialog_loading"
                                @click="file_une_dialog = false"
                            >
                                Anuluj
                            </v-btn>
                            <v-btn
                                text
                                color="success"
                                :loading="file_une_dialog_loading"
                                @click="editFileUrlName"
                            >
                                Zapisz
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- FILE DELETE DIALOG -->
                <v-dialog
                    v-model="file_delete_dialog"
                    persistent
                    max-width="400"
                    scrollable
                >
                    <v-card>
                        <v-card-title> Usuń plik </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text
                            class="pt-5"
                            style="max-height: 70vh"
                        >
                            Czy na pewno usunąć plik <b>"{{ file_delete_dialog_name }}"</b>?
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="grey lighten-1"
                                :disabled="file_delete_dialog_loading"
                                @click="file_delete_dialog = false"
                            >
                                Anuluj
                            </v-btn>
                            <v-btn
                                text
                                color="error"
                                :loading="file_delete_dialog_loading"
                                @click="deleteFile"
                            >
                                Usuń
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import WkMediaManagerFolder from "@/components/WkMediaManager/WkMediaManagerFolder";
import WkMediaManagerFile from "@/components/WkMediaManager/WkMediaManagerFile";
import WkMediaManagerFilePreview from "@/components/WkMediaManager/WkMediaManagerFilePreview";
import WkMediaManagerFolderSelect from "@/components/WkMediaManager/WkMediaManagerFolderSelect";
import WkMediaManagerFilesUploader from "@/components/WkMediaManager/WkMediaManagerFilesUploader";
import WkMediaManagerFileMetadataDetlist from "@/components/WkMediaManager/WkMediaManagerFileMetadataDetlist";
import { formatFileSize } from "@/helpers/formatters";
import { IoEvent } from "../../enums/SocketIo";

export default {
    components: {
        WkMediaManagerFolder,
        WkMediaManagerFile,
        WkMediaManagerFilePreview,
        WkMediaManagerFolderSelect,
        WkMediaManagerFilesUploader,
        WkMediaManagerFileMetadataDetlist
    },

    props: {
        fileSelect: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            show: false,
            fullscreen_mode: false,
            finfo_opened: false,
            finfo_offset: "100px",
            finfo_height: "500px",
            content_max_height: "70vh",

            current_folder: null,
            current_parent_folder: null,
            selected_object: null,
            opening_folder: false,
            inited: false,

            folders: [],
            folders_loading: false,
            folders_loaded: false,
            folders_current_page: 0,
            folders_number_of_pages: 1,

            files: [],
            files_loading: false,
            files_loaded: false,
            files_current_page: 0,
            files_number_of_pages: 1,

            general_ctx_menu: false,
            general_ctx_menu_x: 0,
            general_ctx_menu_y: 0,

            folder_ctx_menu: false,
            folder_ctx_menu_folder: null,
            folder_ctx_menu_x: 0,
            folder_ctx_menu_y: 0,

            folder_create_dialog: false,
            folder_create_dialog_loading: false,
            folder_create_dialog_name: "",

            folder_ne_dialog: false,
            folder_ne_dialog_loading: false,
            folder_ne_dialog_item: null,

            folder_delete_dialog: false,
            folder_delete_dialog_loading: false,
            folder_delete_dialog_id: null,
            folder_delete_dialog_name: "",

            folder_select_dialog: false,
            folder_select_dialog_loading: false,
            folder_select_dialog_mode: "folder",
            folder_select_dialog_subject: "",
            folder_select_dialog_subject_id: "",
            folder_select_dialog_current_folder: null,

            files_uploader: false,
            files_uploader_target: null,

            file_ctx_menu: false,
            file_ctx_menu_file: null,
            file_ctx_menu_x: 0,
            file_ctx_menu_y: 0,

            file_ne_dialog: false,
            file_ne_dialog_loading: false,
            file_ne_dialog_item: null,

            file_une_dialog: false,
            file_une_dialog_loading: false,
            file_une_dialog_item: null,
            file_une_dialog_value: "",

            file_delete_dialog: false,
            file_delete_dialog_loading: false,
            file_delete_dialog_id: null,
            file_delete_dialog_name: "",

            formatFileSize
        };
    },

    computed: {
        previous_folder() {
            if (this.history_stack_index <= 0) return null;
            return this.history_stack[this.history_stack_index - 1];
        },
        next_folder() {
            if (this.history_stack_index >= this.history_stack.length - 1) return null;
            return this.history_stack[this.history_stack_index + 1];
        },
        breadcrumb_items() {
            const A = [];
            if (!this.current_parent_folder && !this.current_folder) {
                // root level
                A.push({
                    _id: null,
                    name: "Folder główny"
                });
            } else if (this.current_folder != null && this.current_parent_folder == null) {
                // podfolder bezpośrednio pod root
                A.push({
                    _id: null,
                    name: "Folder główny"
                });
                A.push({
                    _id: this.current_folder._id,
                    name: this.current_folder.name
                });
            } else {
                // podfolder głębiej
                A.push({
                    _id: this.current_parent_folder._id,
                    name: this.current_parent_folder.name
                });
                A.push({
                    _id: this.current_folder._id,
                    name: this.current_folder.name
                });
            }

            return A;
        }
    },

    methods: {
        open() {
            this.show = true;
            setTimeout(this.layoutCalcs, 1);
            if (!this.inited) {
                this.openFolder(null);
                this.inited = true;
            }
        },
        close() {
            this.show = false;
        },

        timeout(fn, duration) {
            setTimeout(fn, duration);
        },
        hideAllCtxMenus() {
            this.general_ctx_menu = false;
            this.folder_ctx_menu = false;
        },
        showGeneralContextMenu(e) {
            e.preventDefault();
            if (!this.inited) return;

            this.hideAllCtxMenus();
            this.general_ctx_menu_x = e.clientX;
            this.general_ctx_menu_y = e.clientY;
            this.$nextTick(() => {
                this.general_ctx_menu = true;
            });
        },
        showFolderContextMenu(e) {
            e.preventDefault();

            this.hideAllCtxMenus();
            this.folder_ctx_menu_x = e.clientX;
            this.folder_ctx_menu_y = e.clientY;
            this.$nextTick(() => {
                this.folder_ctx_menu = true;
            });
        },
        showFileContextMenu(e) {
            e.preventDefault();

            this.hideAllCtxMenus();
            this.file_ctx_menu_x = e.clientX;
            this.file_ctx_menu_y = e.clientY;
            this.$nextTick(() => {
                this.file_ctx_menu = true;
            });
        },

        layoutCalcs() {
            if (!this.show) return;

            if (window.innerWidth < 960) {
                this.fullscreen_mode = true;
                this.content_max_height =
                    window.innerHeight - this.$refs.dialog_title.offsetHeight + "px";
            } else {
                this.fullscreen_mode = false;
                this.content_max_height = "70vh";
            }

            this.finfo_offset = this.$refs.dialog_title.offsetHeight + "px";
            this.finfo_height = window.innerHeight - this.$refs.dialog_title.offsetHeight + "px";
        },

        selectObject(object_type = "folder", object) {
            if (
                this.selected_object != null &&
                this.selected_object.object_type == object_type &&
                this.selected_object.object_data._id == object._id
            ) {
                // unselect
                this.selected_object = null;
                this.finfo_opened = false;
            } else {
                // select
                this.finfo_opened = true;
                this.selected_object = {
                    object_type,
                    object_data: object
                };
            }
        },

        // folder navigation
        async openFolder(id = null) {
            if (
                this.opening_folder ||
                (this.current_folder == null && id == null && this.inited) ||
                (this.current_folder != null && this.current_folder._id == id)
            ) {
                return;
            }
            this.opening_folder = true;

            // 1. Jeżeli id to null, to otwieramy root folder
            if (id === null) {
                this.current_folder = null;
                this.current_parent_folder = null;
            }
            // 2. W przeciwnym razie pobieramy dane nowego folderu
            else {
                const MF = await this.$axios.$get(`/mediamanager/folders/${id}`);
                this.current_folder = MF.mediamanager_folder;

                if (this.current_folder.parent_folder != null) {
                    const PF = await this.$axios.$get(
                        `/mediamanager/folders/${this.current_folder.parent_folder}`
                    );
                    this.current_parent_folder = PF.mediamanager_folder;
                } else {
                    this.current_parent_folder = null;
                }
            }

            // 4. Czyszczenie danych
            this.folders = [];
            this.folders_loaded = false;
            this.folders_current_page = 0;
            this.folders_number_of_pages = 1;

            this.files = [];
            this.files_loaded = false;
            this.files_current_page = 0;
            this.files_number_of_pages = 1;

            this.selected_object = null;

            // console.log(this.current_folder);
            // console.log(this.current_parent_folder);

            await this.fetchData("folders");
            await this.fetchData("files");

            this.opening_folder = false;
        },

        // data ops methods
        async fetchData(data_type = "folders") {
            if (
                this[data_type + "_loading"] ||
                this[data_type + "_current_page"] >= this[data_type + "_number_of_pages"]
            ) {
                return;
            }

            this[data_type + "_loading"] = true;
            // console.log("f", data_type);
            // await waitForMs(500);

            try {
                const r = await this.$axios.$get(
                    `/mediamanager/${data_type}/?page=${
                        this[data_type + "_current_page"] + 1
                    }&filter_parent_folder=${
                        this.current_folder ? this.current_folder._id : null
                    }&items_per_page=32`
                );

                const response_prop = "mediamanager_" + data_type;
                for (let i = 0; i < r[response_prop].length; i++) {
                    const ix = this[data_type].findIndex(it => it._id == r[response_prop][i]._id);
                    if (ix === -1) {
                        this[data_type].push(r[response_prop][i]);
                    }
                }

                this[data_type + "_loaded"] = true;
                this[data_type + "_current_page"] = r.pagination.current_page;
                this[data_type + "_number_of_pages"] = r.pagination.number_of_pages;
            } catch (err) {
                console.error(err);
            }

            this[data_type + "_loading"] = false;
        },

        // folder crud methods
        folderIoCb(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.fetchData("folders");
            }
        },
        async createFolder() {
            if (this.folder_create_dialog_loading) return;

            const A = [this.$refs.folder_create_dialog_name.validate(true)];
            if (A.indexOf(false) !== -1) return;

            this.folder_create_dialog_loading = true;

            try {
                const r = await this.$axios.$post(`/mediamanager/folders`, {
                    name: this.folder_create_dialog_name,
                    parent_folder: this.current_folder ? this.current_folder._id : undefined
                });

                this.folders.push(r.mediamanager_folder);
                this.sortData("folders");

                this.folder_create_dialog_name = "";
                this.$refs.folder_create_dialog_name.resetValidation();
                this.folder_create_dialog = false;
            } catch (err) {
                console.error(err);
            }

            this.folder_create_dialog_loading = false;
        },
        async editFolderName() {
            if (this.folder_ne_dialog_loading) return;

            const A = [this.$refs.folder_ne_dialog_name.validate(true)];
            if (A.indexOf(false) !== -1) return;

            this.folder_ne_dialog_loading = true;

            try {
                await this.$axios.$put(`/mediamanager/folders/${this.folder_ne_dialog_item._id}`, {
                    name: this.folder_ne_dialog_item.name
                });

                const ix = this.folders.findIndex(it => it._id == this.folder_ne_dialog_item._id);
                if (ix !== -1) {
                    this.folders.splice(ix, 1, {
                        ...this.folders[ix],
                        name: this.folder_ne_dialog_item.name
                    });
                    this.sortData("folders");
                }
                if (
                    this.selected_object &&
                    this.selected_object.object_type == "folder" &&
                    this.selected_object.object_data._id == this.folder_ne_dialog_item._id
                ) {
                    this.selected_object = {
                        ...this.selected_object,
                        object_data: {
                            ...this.selected_object.object_data,
                            name: this.folder_ne_dialog_item.name
                        }
                    };
                }

                this.folder_ne_dialog = false;
                this.folder_ne_dialog_item = null;
            } catch (err) {
                console.error(err);
            }

            this.folder_ne_dialog_loading = false;
        },
        async deleteFolder() {
            if (this.folder_delete_dialog_loading) return;
            this.folder_delete_dialog_loading = true;

            try {
                await this.$axios.$delete(`/mediamanager/folders/${this.folder_delete_dialog_id}`);

                const ix = this.folders.findIndex(it => it._id == this.folder_delete_dialog_id);
                if (ix !== -1) {
                    this.folders.splice(ix, 1);
                    this.sortData("folders");

                    this.$message({
                        type: "success",
                        msg: "Folder został usunięty pomyślnie"
                    });
                }
                if (
                    this.selected_object &&
                    this.selected_object.object_type == "folder" &&
                    this.selected_object.object_data._id == this.folder_delete_dialog_id
                ) {
                    this.selected_object = null;
                }

                this.folder_delete_dialog = false;
            } catch (err) {
                console.error(err);
            }

            this.folder_delete_dialog_loading = false;
        },

        // files crud methods
        onNewFileUploaded(file) {
            this.files.push(file);
            this.sortData("files");
        },
        filesIoCb(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.fetchData("files");
            }
        },
        async editFileName() {
            if (this.file_ne_dialog_loading) return;

            const A = [this.$refs.file_ne_dialog_name.validate(true)];
            if (A.indexOf(false) !== -1) return;

            this.file_ne_dialog_loading = true;

            try {
                await this.$axios.$put(`/mediamanager/files/${this.file_ne_dialog_item._id}`, {
                    name: this.file_ne_dialog_item.name
                });

                const ix = this.files.findIndex(it => it._id == this.file_ne_dialog_item._id);
                if (ix !== -1) {
                    this.files.splice(ix, 1, {
                        ...this.files[ix],
                        name: this.file_ne_dialog_item.name
                    });
                    this.sortData("files");
                }
                if (
                    this.selected_object &&
                    this.selected_object.object_type == "file" &&
                    this.selected_object.object_data._id == this.file_ne_dialog_item._id
                ) {
                    this.selected_object = {
                        ...this.selected_object,
                        object_data: {
                            ...this.selected_object.object_data,
                            name: this.file_ne_dialog_item.name
                        }
                    };
                }

                this.file_ne_dialog = false;
                this.file_ne_dialog_item = null;
            } catch (err) {
                console.error(err);
            }

            this.file_ne_dialog_loading = false;
        },
        async editFileUrlName() {
            if (this.file_une_dialog_loading) return;

            const A = [this.$refs.file_une_dialog_value.validate(true)];
            if (A.indexOf(false) !== -1) return;

            this.file_une_dialog_loading = true;

            try {
                await this.$axios.$put(`/mediamanager/files/${this.file_une_dialog_item._id}`, {
                    url_name: this.file_une_dialog_value
                });

                const new_uname =
                    this.file_une_dialog_item.url_name.slice(0, 9) + this.file_une_dialog_value;
                const ix = this.files.findIndex(it => it._id == this.file_une_dialog_item._id);
                if (ix !== -1) {
                    this.files.splice(ix, 1, {
                        ...this.files[ix],
                        url_name: new_uname
                    });
                    this.sortData("files");
                }
                if (
                    this.selected_object &&
                    this.selected_object.object_type == "file" &&
                    this.selected_object.object_data._id == this.file_une_dialog_item._id
                ) {
                    this.selected_object = {
                        ...this.selected_object,
                        object_data: {
                            ...this.selected_object.object_data,
                            url_name: new_uname
                        }
                    };
                }

                this.file_une_dialog = false;
                this.file_une_dialog_item = null;
            } catch (err) {
                console.error(err);
            }

            this.file_une_dialog_loading = false;
        },
        getFileLink(file) {
            return file.url;
        },
        copyFileLink(file) {
            navigator.clipboard.writeText(this.getFileLink(file));
            this.$message({
                type: "success",
                msg: "Skopiowano!"
            });
        },
        downloadFile(file) {
            const a = document.createElement("a");
            a.setAttribute("href", this.getFileLink(file));
            a.setAttribute("target", "_blank");
            a.setAttribute("download", file.name);

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        async deleteFile() {
            if (this.file_delete_dialog_loading) return;
            this.file_delete_dialog_loading = true;

            try {
                await this.$axios.$delete(`/mediamanager/files/${this.file_delete_dialog_id}`);

                const ix = this.files.findIndex(it => it._id == this.file_delete_dialog_id);
                if (ix !== -1) {
                    this.files.splice(ix, 1);
                    this.sortData("files");

                    this.$message({
                        type: "success",
                        msg: "Plik został usunięty pomyślnie"
                    });
                }
                if (
                    this.selected_object &&
                    this.selected_object.object_type == "file" &&
                    this.selected_object.object_data._id == this.file_delete_dialog_id
                ) {
                    this.selected_object = null;
                }

                this.file_delete_dialog = false;
            } catch (err) {
                console.error(err);
            }

            this.file_delete_dialog_loading = false;
        },

        // shared crud methods
        async onFolderSelectDialogSubmit(folder_id) {
            if (this.folder_select_dialog_loading) {
                return;
            }
            this.folder_select_dialog_loading = true;

            try {
                if (this.folder_select_dialog_mode == "folder") {
                    await this.$axios.$put(
                        `/mediamanager/folders/${this.folder_select_dialog_subject_id}`,
                        {
                            parent_folder: folder_id
                        }
                    );

                    const ix = this.folders.findIndex(
                        it => it._id == this.folder_select_dialog_subject_id
                    );
                    if (ix !== -1 && this.folders[ix].parent_folder != folder_id) {
                        this.folders.splice(ix, 1);
                        this.sortData("folders");

                        this.$message({
                            type: "success",
                            msg: "Folder został przeniesiony"
                        });
                    }
                    if (
                        this.selected_object &&
                        this.selected_object.object_type == "folder" &&
                        this.selected_object.object_data._id == this.folder_select_dialog_subject_id
                    ) {
                        this.selected_object = null;
                    }
                } else if (this.folder_select_dialog_mode == "file") {
                    const ix = this.files.findIndex(
                        it => it._id == this.folder_select_dialog_subject_id
                    );
                    const PREV_PARENT_FOLDER = ix === -1 ? undefined : this.files[ix].parent_folder;

                    await this.$axios.$put(
                        `/mediamanager/files/${this.folder_select_dialog_subject_id}`,
                        {
                            parent_folder: folder_id
                        }
                    );

                    if (ix !== -1 && PREV_PARENT_FOLDER != folder_id) {
                        this.files.splice(ix, 1);
                        this.sortData("files");

                        this.$message({
                            type: "success",
                            msg: "Plik został przeniesiony"
                        });
                    }
                    if (
                        this.selected_object &&
                        this.selected_object.object_type == "file" &&
                        this.selected_object.object_data._id == this.folder_select_dialog_subject_id
                    ) {
                        this.selected_object = null;
                    }
                }

                this.folder_select_dialog = false;
            } catch (err) {
                console.error(err);
            }

            this.folder_select_dialog_loading = false;
        },

        sortData(data_type = "folders") {
            this[data_type].sort((a, b) => a.name.localeCompare(b.name));
        },

        // validators
        validatorRequired(v) {
            if (!v || v.length === 0) {
                return "To pole jest wymagane";
            }
            return true;
        },
        validatorFolderName(v) {
            if (v.length > 64) {
                return "Nazwa folderu może mieć maksymalnie 64 znaki długości";
            }
            if (!/^[A-ZĄĆĘŁŃÓŚŻŹa-ząćęłńóśżź0-9 _\-]{1,64}$/.test(v)) {
                return "Nazwa folderu może zawierać tylko litery, cyfry, spację oraz znaki: '_', '-'";
            }
            return true;
        },
        validatorFileName(v) {
            if (v.length > 64) {
                return "Nazwa pliku może mieć maksymalnie 64 znaki długości";
            }
            if (!/^[A-ZĄĆĘŁŃÓŚŻŹa-ząćęłńóśżź0-9 \(\)_\-\.]{1,64}$/.test(v)) {
                return "Nazwa folderu może zawierać tylko litery, cyfry, spację oraz znaki: '_', '-', '(', ')' i '.'";
            }
            return true;
        },
        validatorFileUrlName(v) {
            if (v.length > 64) {
                return "Ścieżka do pliku może mieć maksymalnie 64 znaki długości";
            }
            if (!/^[a-z0-9\,\.\-]{1,64}$/.test(v)) {
                return "Ścieżka do folderu może zawierać tylko litery (bez polskich znaków), cyfry oraz znaki: '-', ',' i '.'";
            }
            return true;
        },

        // socket handlers
        onSocketFileUpdated(file) {
            const ix = this.files.findIndex(it => it._id == file._id);
            if (ix === -1) return;

            this.files.splice(ix, 1, {
                ...this.files[ix],
                ...file
            });
        },
        onSocketFolderUpdated(folder) {
            const ix = this.folders.findIndex(it => it._id == folder._id);
            if (ix === -1) return;

            this.folders.splice(ix, 1, {
                ...this.folders[ix],
                ...folder
            });

            if (this.selected_object && this.selected_object.object_type === "folder") {
                this.selected_object = {
                    ...this.selected_object,
                    object_data: this.folders[ix]
                };
            }
        }
    },

    mounted() {
        if (!this.$store.state.initial_layout_set) return;
        window.addEventListener("resize", this.layoutCalcs, { passive: true });
        this.$io.on(IoEvent.MEDIAMANAGER_FILE_UPDATED, this.onSocketFileUpdated);
        this.$io.on(IoEvent.MEDIAMANAGER_FOLDER_UPDATED, this.onSocketFolderUpdated);
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.layoutCalcs, { passive: true });
        this.$io.off(IoEvent.MEDIAMANAGER_FILE_UPDATED, this.onSocketFileUpdated);
        this.$io.off(IoEvent.MEDIAMANAGER_FOLDER_UPDATED, this.onSocketFolderUpdated);
    }
};
</script>
