<template>
    <div class="tiptap-wysiwyg-colorpicker">
        <v-dialog
            scrollable
            v-model="show"
            max-width="350px"
            @click:outside="closeDialog"
        >
            <v-card>
                <v-card-title>
                    {{ title }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="max-height: 80vh; overflow-x: hidden;">
                    <h4 class="text-caption text-uppercase font-weight-medium mt-4 mb-1">
                        Kolory marki
                    </h4>
                    <div class="tiptap-wysiwyg-colorpicker__cgrid">
                        <div class="tiptap-wysiwyg-colorpicker__citem">
                            <TipTapWysiwygColorPickerButton 
                                :color="$vuetify.theme.defaults.light.primary"
                                title="Kolor wiodący"
                                @pick="onColorPick"
                            />
                        </div>
                        <div class="tiptap-wysiwyg-colorpicker__citem">
                            <TipTapWysiwygColorPickerButton 
                                :color="$vuetify.theme.defaults.light.secondary"
                                title="Kolor drugorzędny"
                                @pick="onColorPick"
                            />
                        </div>
                    </div>

                    <h4 class="text-caption text-uppercase font-weight-medium mt-4 mb-1">
                        Kolory predefiniowane
                    </h4>
                    <div 
                        class="tiptap-wysiwyg-colorpicker__cgrid"
                        v-for="(row, index) in default_colors"
                        :key="'row_' + index"
                    >
                        <div 
                            class="tiptap-wysiwyg-colorpicker__citem"
                            v-for="color in row"
                            :key="color"
                        >
                            <TipTapWysiwygColorPickerButton 
                                :color="color"
                                :title="color"
                                @pick="onColorPick"
                            />
                        </div>
                    </div>

                    <v-btn
                        text
                        color="grey"
                        block
                        class="mt-4"
                        small
                        @click.stop="custom_color_dialog = true"
                    > 
                        <v-icon size="18" class="mr-1">mdi-eyedropper-variant</v-icon>
                        Kolor niestandardowy
                    </v-btn>
                    <v-btn
                        text
                        color="grey"
                        block
                        class="mt-1"
                        small
                        @click="onColorPick(null)"
                    > 
                        <v-icon size="18" class="mr-1">mdi-water-off</v-icon>
                        Wyczyść kolor
                    </v-btn>

                    <!-- CUSTOM COLOR PICKER -->
                    <v-dialog
                        persistent
                        v-model="custom_color_dialog"
                        max-width="450px"
                    >
                        <v-card>
                            <v-card-text class="pt-5">
                                <v-color-picker
                                    dot-size="14"
                                    v-model="custom_color_dialog_value"
                                    width="400px"
                                    mode="hexa"
                                />
                                <div class="d-flex mt-4">
                                    <v-btn
                                        text
                                        color="grey lighten-1"
                                        @click="custom_color_dialog = false"
                                    >
                                        Anuluj
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="success"
                                        @click="_ => {
                                            custom_color_dialog = false;
                                            onColorPick(custom_color_dialog_value);
                                        }"
                                    >
                                        Wybierz kolor
                                    </v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-card-text>  
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import TipTapWysiwygColorPickerButton from './TipTapWysiwygColorPickerButton.vue';
export default {
    
    components: {
        TipTapWysiwygColorPickerButton
    },

    props: {
        title: {
            type: String,
            default: 'Wybierz kolor'
        },
        value: {
            type: Boolean,
            default: true
        }
    },
    
    data(){
        return {
            default_colors: [
                ['#000000', '#1a1a1a', '#333333', '#4d4d4d', '#666666', '#808080', '#999999', '#b3b3b3', '#cccccc', '#e6e6e6', '#ffffff'],
                ['#590d0d', '#701212', '#861717', '#9c1d1d', '#b12424', '#c52c2c', '#d03c3c', '#d35555', '#d76d6d', '#db8585', '#e09c9c'],
                ['#593b0d', '#704a12', '#865a17', '#9c691d', '#b17924', '#c5882c', '#d0953c', '#d3a155', '#d7ad6d', '#dbb985', '#e0c59c'],
                ['#4a590d', '#5d7012', '#708617', '#839c1d', '#95b124', '#a7c52c', '#b3d03c', '#bad355', '#c2d76d', '#cadb85', '#d2e09c'],
                ['#1c590d', '#257012', '#2d8617', '#379c1d', '#40b124', '#4ac52c', '#5ad03c', '#6ed355', '#83d76d', '#96db85', '#aae09c'],
                ['#0d5959', '#127070', '#178686', '#1d9c9c', '#24b1b1', '#2cc5c5', '#3cd0d0', '#55d3d3', '#6dd7d7', '#85dbdb', '#9ce0e0'],
                ['#0d2b59', '#123770', '#174486', '#1d509c', '#245db1', '#2c69c5', '#3c78d0', '#5588d3', '#6d98d7', '#85a7db', '#9cb7e0'],
                ['#1c0d59', '#251270', '#2d1786', '#371d9c', '#4024b1', '#4a2cc5', '#5a3cd0', '#6e55d3', '#836dd7', '#9685db', '#aa9ce0'],
                ['#4a0d59', '#5d1270', '#701786', '#831d9c', '#9524b1', '#a72cc5', '#b33cd0', '#ba55d3', '#c26dd7', '#ca85db', '#d29ce0'],
                ['#590d3b', '#70124a', '#86175a', '#9c1d69', '#b12479', '#c52c88', '#d03c95', '#d355a1', '#d76dad', '#db85b9', '#e09cc5']
            ],

            show: false,

            custom_color_dialog: false,
            custom_color_dialog_value: '#ff0000'
        }
    },

    watch: {
        value(v){
            if(v !== this.show) this.show = v;
        }
    },

    methods: {
        onColorPick(color){
            this.closeDialog();
            this.$emit('color-pick', color);
        },
        closeDialog(){
            this.$emit('input', false);
        }
    },  

    created(){
        this.show = this.value;
    }

}
</script>