export default function waitForMs(ms) {
    return new Promise((res, rej) => setTimeout(res, ms));
}

export class Stopwatch {
    start_time = null;

    constructor() {
        this.start_time = Date.now();
    }

    waitUntil(milliseconds) {
        return new Promise(async resolve => {
            const end_time = Date.now();

            if (end_time - this.start_time < milliseconds) {
                await waitForMs(milliseconds - (end_time - this.start_time));
            }

            return resolve();
        });
    }
}
