<template>
    <div class="default-layout">
        <v-navigation-drawer
            app
            v-model="sidemenu"
        >
            <template v-slot:prepend>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="text-h6">
                            {{ $store.state.COMPANY_NAME }}
                        </v-list-item-title>
                        <v-list-item-subtitle> Strefa administracyjna </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </template>

            <v-list
                dense
                nav
            >
                <div
                    v-for="(item, ix) in filteredNavItems"
                    :key="ix"
                >
                    <v-list-item
                        v-if="item.entity_type === 'link'"
                        :to="item.to"
                        :exact="item.exact === true"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <div
                        class="mt-1"
                        v-else
                    >
                        <v-subheader class="mb-n1">{{ item.name }}</v-subheader>
                        <v-list-item
                            v-for="(l, ix2) in item.links"
                            :key="ix2"
                            :to="l.to"
                            :exact="l.exact === true"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ l.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ l.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </div>
            </v-list>

            <template v-slot:append>
                <div class="px-4 py-2 text-caption grey--text text-center">
                    &copy;Risify 2023-{{ new Date().getFullYear() }} (v{{
                        $store.state.APP_VERSION
                    }})
                </div>
            </template>
        </v-navigation-drawer>

        <v-app-bar app>
            <v-app-bar-nav-icon @click="sidemenu = !sidemenu">
                <v-icon>{{ sidemenu ? "mdi-arrow-collapse-left" : "mdi-menu" }}</v-icon>
            </v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <div class="mr-2">
                <AdminNotificationsList />
            </div>
            <UserTile
                v-model="user"
                tag="router-link"
                :to="{
                    name: 'my-account__main'
                }"
                details-key="role"
                hide-name-details
            />
            <v-btn
                icon
                small
                class="ml-2"
                title="Wyloguj"
                @click="$store.dispatch('auth/logOut')"
            >
                <v-icon :size="20">mdi-logout</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main>
            <v-container fluid>
                <transition
                    :name="
                        $route.name != 'helpdesk__list' && $route.name != 'helpdesk__details'
                            ? 'fade'
                            : 'none'
                    "
                    mode="out-in"
                >
                    <div
                        class="pa-0 pa-md-3"
                        :key="$route.fullPath"
                    >
                        <slot />
                    </div>
                </transition>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import AdminNotificationsList from "../components/AdminNotifications/AdminNotificationsList.vue";

export default {
    components: { AdminNotificationsList },

    data() {
        return {
            sidemenu: true,

            nav_items: [
                {
                    entity_type: "link",
                    icon: "mdi-home",
                    title: "Dashboard",
                    to: {
                        name: "home"
                    },
                    required_permissions: [],
                    exact: true
                },
                {
                    entity_type: "group",
                    name: "Użytkownicy",
                    links: [
                        {
                            icon: "mdi-account-group",
                            title: "Lista użytkowników",
                            to: {
                                name: "users__list"
                            },
                            required_permissions: "users.list"
                        }
                    ]
                },
                {
                    entity_type: "group",
                    name: "Wizyty",
                    links: [
                        {
                            icon: "mdi-calendar-month",
                            title: "Kalendarz wizyt",
                            to: {
                                name: "calendar"
                            },
                            required_permissions: "appointments.list"
                        },
                        {
                            icon: "mdi-list-box",
                            title: "Lista wizyt",
                            to: {
                                name: "appointments__list"
                            },
                            required_permissions: "appointments.list"
                        },
                        {
                            icon: "mdi-forum",
                            title: "Czaty",
                            to: {
                                name: "chats__list"
                            },
                            required_permissions: "chats.list"
                        }
                    ]
                },
                {
                    entity_type: "group",
                    name: "Terapeuci",
                    links: [
                        {
                            icon: "mdi-invoice-list",
                            title: "Rozliczenia",
                            to: {
                                name: "user-settlements__list"
                            },
                            required_permissions: "user_settlements.list"
                        },
                        {
                            icon: "mdi-star",
                            title: "Oceny",
                            to: {
                                name: "therapist_rates__list"
                            },
                            required_permissions: "therapist_rates.list"
                        },
                        {
                            icon: "mdi-account-supervisor-circle",
                            title: "Rekomendacje",
                            to: {
                                name: "therapist-recommendations__list"
                            },
                            required_permissions: "therapist_recommendations.list"
                        },
                        {
                            icon: "mdi-video-account",
                            title: "Opinie wideo",
                            to: {
                                name: "video-testimonials__list"
                            },
                            required_permissions: "therapist_recommendations.list"
                        },
                        {
                            icon: "mdi-arrow-decision-outline",
                            title: "Symulator rekomendacji",
                            to: {
                                name: "recommendation-simulator__list"
                            },
                            required_permissions: "therapist_recommendations.list"
                        }
                    ]
                },
                {
                    entity_type: "group",
                    name: "Sklep",
                    links: [
                        {
                            icon: "mdi-cart",
                            title: "Zamówienia",
                            to: {
                                name: "checkout_sessions__list"
                            },
                            required_permissions: "checkout_sessions.list"
                        },
                        {
                            icon: "mdi-package",
                            title: "Produkty",
                            to: {
                                name: "products__list"
                            },
                            required_permissions: "products.list"
                        },
                        {
                            icon: "mdi-sale",
                            title: "Kody zniżkowe",
                            to: {
                                name: "discount_codes__list"
                            },
                            required_permissions: "discount_codes.list"
                        }
                    ]
                },
                {
                    entity_type: "group",
                    name: "Rekrutacja terapeutów",
                    links: [
                        {
                            icon: "mdi-clipboard-text-clock",
                            title: "Archiwum wniosków",
                            to: {
                                name: "recruitment__list"
                            },
                            required_permissions: "therapist_job_applications.list"
                        },
                        {
                            icon: "mdi-table-large",
                            title: "Tablica wniosków",
                            to: {
                                name: "recruitment__table"
                            },
                            required_permissions: "therapist_job_applications.list"
                        }
                    ]
                },
                {
                    entity_type: "group",
                    name: "Centrum pomocy",
                    links: [
                        {
                            icon: "mdi-frequently-asked-questions",
                            title: "Pytania FAQ",
                            to: {
                                name: "faq_questions__list"
                            },
                            required_permissions: "faq_questions.list"
                        },
                        {
                            icon: "mdi-lifebuoy",
                            title: "Zgłoszenia pomocy",
                            to: {
                                name: "helpdesk__list"
                            },
                            required_permissions: "helpdesk_threads.list"
                        }
                    ]
                },
                {
                    entity_type: "group",
                    name: "Ankiety",
                    links: [
                        {
                            icon: "mdi-clipboard-check",
                            title: "Lista ankiet",
                            to: {
                                name: "surveys__list"
                            },
                            required_permissions: "surveys.list"
                        },
                        {
                            icon: "mdi-clipboard-text",
                            title: "Lista odpowiedzi",
                            to: {
                                name: "survey-responses__list"
                            },
                            required_permissions: "survey_responses.list",
                            exact: true
                        },
                        {
                            icon: "mdi-poll",
                            title: "Statystyki odpowiedzi",
                            to: {
                                name: "survey-responses__stats"
                            },
                            required_permissions: "survey_responses.list"
                        }
                    ]
                },
                {
                    entity_type: "group",
                    name: "Quizy",
                    links: [
                        {
                            icon: "mdi-folder-question",
                            title: "Lista Quizów",
                            to: {
                                name: "quizzes__list"
                            },
                            required_permissions: "quizzes.list"
                        },
                        {
                            icon: "mdi-chart-gantt",
                            title: "Statystyki",
                            to: {
                                name: "quizstats"
                            },
                            required_permissions: "quiz_sessions.list"
                        },
                        {
                            icon: "mdi-poll",
                            title: "Statystyki (Onboarding)",
                            to: {
                                name: "quizstats-onboarding"
                            },
                            required_permissions: "onboarding_sessions.list"
                        }
                    ]
                },
                {
                    entity_type: "group",
                    name: "Program poleceń",
                    links: [
                        {
                            icon: "mdi-email-fast",
                            title: "Lista Poleceń",
                            to: {
                                name: "recommend-friends__list"
                            },
                            required_permissions: "user_affiliate_program_register_invites.list"
                        },
                        {
                            icon: "mdi-chart-line",
                            title: "Statystyki",
                            to: {
                                name: "recommend-friends__stats"
                            },
                            required_permissions: "user_affiliate_program_register_invites.list"
                        }
                    ]
                },
                {
                    entity_type: "group",
                    name: "Blog",
                    links: [
                        {
                            icon: "mdi-post",
                            title: "Wpisy na blogu",
                            to: {
                                name: "wpblog__list"
                            },
                            required_permissions: "wordpress_blog_posts.list"
                        }
                    ]
                },
                {
                    entity_type: "group",
                    name: "Szkolenia",
                    links: [
                        {
                            icon: "mdi-cast-education",
                            title: "Lista Szkoleń",
                            to: {
                                name: "online-courses__list"
                            },
                            required_permissions: "online_courses.list"
                        }
                    ]
                },
                {
                    entity_type: "group",
                    name: "Wiadomości z biura",
                    links: [
                        {
                            icon: "mdi-email",
                            title: "Wiadomości z biura",
                            to: {
                                name: "office-messages__list"
                            },
                            required_permissions: "office_messages.list"
                        }
                    ]
                },
                {
                    entity_type: "group",
                    name: "Ustawienia globalne",
                    links: [
                        {
                            icon: "mdi-file-document-edit",
                            title: "Szablony dokumentów",
                            to: {
                                name: "document-templates__list"
                            },
                            required_permissions: "document_templates.list"
                        },
                        {
                            icon: "mdi-cog",
                            title: "Ustawienia globalne",
                            to: {
                                name: "global-settings__list"
                            },
                            required_permissions: "global_settings.list"
                        }
                    ]
                },
                // {
                //     icon: "mdi-shopping",
                //     title: "Standalone Orders",
                //     to: {
                //         name: "standalone-orders__list"
                //     },
                //     required_permissions: "standalone_checkout_sessions.list"
                // },

                // {
                //     icon: "mdi-snail",
                //     title: "Test komponentów",
                //     to: {
                //         name: "test"
                //     },
                //     required_permissions: []
                // }
            ]
        };
    },

    computed: {
        user() {
            return this.$store.state.auth.user || {};
        },
        filteredNavItems() {
            const FI = [];

            for (let i = 0; i < this.nav_items.length; i++) {
                if (this.nav_items[i].entity_type === "link") {
                    if (
                        this.$store.getters["auth/userHasPermissions"](
                            this.nav_items[i].required_permissions
                        )
                    ) {
                        FI.push(this.nav_items[i]);
                    }
                } else if (this.nav_items[i].entity_type === "group") {
                    const PG = {
                        ...this.nav_items[i],
                        links: []
                    };

                    if (this.nav_items[i].links) {
                        for (let j = 0; j < this.nav_items[i].links.length; j++) {
                            if (
                                this.$store.getters["auth/userHasPermissions"](
                                    this.nav_items[i].links[j].required_permissions
                                )
                            ) {
                                PG.links.push(this.nav_items[i].links[j]);
                            }
                        }
                    }

                    if (PG.links.length > 0) {
                        FI.push(PG);
                    }
                }
            }

            return FI;
        }
    },

    mounted() {
        if (window.innerWidth < 600) {
            this.$nextTick(_ => {
                this.sidemenu = false;
            });
        }
    }
};
</script>
