import store from "../store";

export function playNewNotificationSound(vol = 1) {
    try {
        const a = new Audio(store.state.SELF_URL + "/static/sounds/notification-alert.mp3");
        a.volume = vol;
        a.play();
    } catch (err) {
        console.error(err);
    }
}
