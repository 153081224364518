"use strict";

export const MediamanagerFileDisplayType = Object.freeze({
    IMAGE: "image",
    VIDEO: "video",
    SOUND: "sound",
    WORD: "word",
    EXCEL: "excel",
    POWERPOINT: "powerpoint",
    PDF: "pdf",
    TXT: "txt",
    ARCHIVE: "archive",
    UNKNOWN: "unknown"
});
