<template>
    <div
        class="wkmmf rounded-lg"
        :class="{
            'indigo lighten-5': selectedItemId == item._id,
            'grey lighten-4': selectedItemId != item._id
        }"
        @click="selectItem"
        :title="item.name"
    >
        <div class="wkmmf__overlay"></div>
        <div class="wkmmf__content">
            <div class="wkmmf__header">
                <div class="wkmmf__icon">
                    <WkMediaManagerFileIcon
                        :file-type="item.display_type"
                        size="20"
                    ></WkMediaManagerFileIcon>
                </div>
                <div class="wkmmf__name text-truncate">
                    {{ item.name }}
                </div>
                <div class="wkmmf__actions">
                    <v-btn
                        small
                        icon
                        color="secondary"
                        @contextmenu.stop.prevent
                        @click.stop="e => $nextTick(() => $emit('contextmenu-request', e))"
                    >
                        <v-icon size="20">mdi-dots-vertical</v-icon>
                    </v-btn>
                </div>
            </div>
            <div class="wkmmf__preview">
                <WkMediaManagerFilePreview :file="item" />
            </div>
        </div>
    </div>
</template>

<script>
import WkMediaManagerFileIcon from "./WkMediaManagerFileIcon.vue";
import WkMediaManagerFilePreview from "./WkMediaManagerFilePreview.vue";

export default {
    components: { WkMediaManagerFileIcon, WkMediaManagerFilePreview },

    props: {
        item: {
            type: Object,
            required: true
        },
        selectedItemId: {
            type: String,
            default: "unknown"
        }
    },

    methods: {
        selectItem() {
            this.$emit("file-selected");
        }
    }
};
</script>
