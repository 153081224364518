<template>
    <div
        :class="{
            'user-detailed-tile align-center text-decoration-none': true,
            'user-detailed-tile--clickable': clickDetails && copyable
        }"
        @click="showDetails"
    >
        <template v-if="id">
            <div class="text-body-2 d-flex">
                <v-avatar
                    :class="{
                        'mr-2': computedAvatarSize > 16,
                        'mr-1': computedAvatarSize <= 16
                    }"
                    :size="computedAvatarSize"
                    color="primary"
                >
                    <v-img
                        v-if="profileImage"
                        :src="profileImage"
                    />
                    <div
                        class="text-center white--text font-weight-medium"
                        v-else
                        :style="{
                            'font-size': `${Math.max(0.5, 0.05 * (computedAvatarSize / 2))}rem`,
                            'line-height': 1
                        }"
                    >
                        {{ computedInitials }}
                    </div>
                </v-avatar>
                <div>
                    <div
                        class="grey--text text-caption"
                        style="margin-top: -4px"
                        v-if="title"
                    >
                        {{ title }}
                    </div>
                    <div
                        class="font-weight-medium"
                        style="color: #434343"
                    >
                        {{ name }}
                        <span
                            class="font-weight-regular"
                            v-if="
                                !hideNameDetails &&
                                $store.state.auth.value &&
                                $store.state.auth.value._id == id
                            "
                        >
                            (Ty)
                        </span>
                    </div>
                </div>
            </div>
            <div class="text-body-2">
                <div
                    class="grey--text text-caption d-block"
                    style="cursor: pointer; width: max-content"
                    @click="handleEmailClick"
                >
                    {{ email }}
                </div>
                <div
                    v-if="phone && phone.phone_cc && phone.phone"
                    class="grey--text text-caption"
                    style="cursor: pointer; width: max-content"
                    @click="handlePhoneClick"
                >
                    {{ `+${phone.phone_cc} ${phone.phone}` }}
                </div>
            </div>
        </template>
        <template v-else>
            <v-skeleton-loader
                class="mr-2"
                :width="computedAvatarSize"
                :height="computedAvatarSize"
                type="avatar"
            />
            <div>
                <v-skeleton-loader
                    width="90"
                    height="12"
                    type="text"
                />
                <v-skeleton-loader
                    width="60"
                    height="8"
                    type="text"
                    class="mt-1"
                />
            </div>
        </template>
    </div>
</template>

<script>
import { UserRoleLabel } from "../enums/User";

export default {
    props: {
        id: {
            type: String
        },
        name: {
            type: String,
            required: true
        },
        profileImage: {
            type: String
        },
        avatarSize: {
            type: [Number, String],
            default: 42
        },
        title: {
            type: String
        },
        email: {
            type: String
        },
        phone: {
            type: Object
        },
        clickDetails: {
            type: Boolean,
            default: false
        },
        hideNameDetails: {
            type: Boolean,
            default: false
        },
        copyable: {
            type: Boolean,
            default: true
        },
        mindgram: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            user_role_labels: UserRoleLabel
        };
    },

    computed: {
        computedAvatarSize() {
            return parseInt(this.avatarSize);
        },
        computedInitials() {
            return this.name
                ? this.name
                      .split(" ")
                      .map(n => n[0])
                      .join("")
                      .toUpperCase()
                : "";
        }
    },

    methods: {
        copyString(string) {
            this.$copyText(string).then(() => {
                this.$message({
                    type: "success",
                    msg: "Skopiowano!"
                });
            });
        },

        handleEmailClick(event) {
            if (this.copyable) {
                event.stopPropagation();
                this.copyString(this.email);
            }
        },

        handlePhoneClick(event) {
            if (this.copyable) {
                event.stopPropagation();
                this.copyString(`+${this.phone.phone_cc} ${this.phone.phone}`);
            }
        },

        showDetails(event) {
            if (this.clickDetails && !this.mindgram) {
                event.stopPropagation();
                const routeData = this.$router.resolve({
                    name: "users__edit",
                    params: {
                        id: this.id
                    }
                });
                window.open(routeData.href, "_blank");
            } else if (this.clickDetails && this.mindgram) {
                this.$message({
                    type: "info",
                    msg: "Wybrany użytkownik jest użytkownikiem Mindgram. Szczegóły nie są dostępne."
                });
            }
        }
    }
};
</script>
