export const AdminNotificationName = Object.freeze({
    NEW_HELPDESK_THREAD: "new_helpdesk_thread",
    NEW_HELPDESK_MESSAGE: "new_helpdesk_message",
    APPOINTMENT_HOST_ABSENCE_5M: "appointment_host_absence_5m",
    APPOINTMENT_HOST_ABSENCE_10M: "appointment_host_absence_10m",
    THUSAPPR_CHAT_MENTION: "thusappr_chat_mention",
    APPOINTMENT_RESCHEDULE_PROPOSAL_EXPIRED: "arp_expired",
    PAYMENT_INTENT_STRIPE_REFUND_ERROR: "pi_sre_error"
});

export const AdminNotificationTargetObject = Object.freeze({
    HELPDESK_THREAD: "helpdesk_thread",
    APPOINTMENT: "appointment",
    CHAT: "chat",
    CHECKOUT_SESSION: "checkout_session",
    PAYMENT_INTENT: "payment_intent"
});
