<template>
    <v-sheet
        outlined
        rounded
        class="pa-3"
    >
        <div class="text-body-1 mb-1">Warunek #{{ num }}</div>
        <div class="text-caption grey--text text-uppercase pb-1">jeżeli:</div>
        <ExpressionsWizardIfblock
            :depth="1"
            :nesting-limit="nestingLimit"
            :quiz-boards="quizBoards"
            v-model="lv.if"
            @input="emitChange"
            ref="if_block_wizard"
        />
        <div class="text-caption grey--text text-uppercase pt-2 pb-1">
            to <span class="font-weight-bold">wykonaj akcję</span>:
        </div>
        <div>
            <ExpressionsWizardActionSelector
                :allowed-actions="allowedActions"
                :quiz-boards="quizBoards"
                :rendercontentflow-board-id="rendercontentflowBoardId"
                v-model="lv.then"
                @input="emitChange"
                ref="then_action_selector"
            />
        </div>
        <div class="text-caption grey--text text-uppercase d-flex align-center pt-2 pb-1">
            w przeciwnym wypadku<span
                class="font-weight-bold ml-1"
                v-if="elsecond_type !== null"
                >{{
                    elsecond_type === "action" ? " wykonaj akcję" : "sprawdź kolejny warunek"
                }}</span
            >:

            <v-btn
                x-small
                color="grey"
                class="ml-1"
                plain
                v-if="elsecond_type !== null"
                @click="elsecond_type = null"
            >
                Zmień
            </v-btn>
        </div>
        <div>
            <!-- WYBÓR RODZAJU ELSE -->
            <v-row
                dense
                v-if="elsecond_type === null"
            >
                <v-col
                    cols="12"
                    sm="6"
                >
                    <div class="pa-4 rounded grey lighten-4 text-center">
                        <v-btn
                            small
                            color="primary"
                            @click="setupElseCondition('ifblock')"
                            v-if="num < elseifLimit"
                        >
                            <v-icon
                                size="16"
                                class="mr-1"
                                >mdi-plus</v-icon
                            >
                            Warunek alternatywny
                        </v-btn>
                        <div
                            class="text-caption grey--text"
                            style="min-height: 28px; line-height: 28px"
                            v-else
                        >
                            Osiągnięto limit warunków alternatywnych ({{ elseifLimit }})
                        </div>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <div class="pa-4 rounded grey lighten-4 text-center">
                        <v-btn
                            small
                            color="primary"
                            @click="setupElseCondition('action')"
                        >
                            <v-icon
                                size="16"
                                class="mr-1"
                                >mdi-plus</v-icon
                            >
                            Akcja alternatywna
                        </v-btn>
                    </div>
                </v-col>
            </v-row>

            <!-- ELSE JAKO AKCJA -->
            <ExpressionsWizardActionSelector
                v-else-if="elsecond_type === 'action'"
                :allowed-actions="allowedActions"
                :quiz-boards="quizBoards"
                :rendercontentflow-board-id="rendercontentflowBoardId"
                v-model="lv.else"
                @input="emitChange"
                ref="else_action_selector"
            />

            <!-- ELSE JAKO KOLEJNY IF -->
            <ExpressionsWizardComplexIfCreator
                v-else-if="elsecond_type === 'ifblock'"
                v-model="lv.else"
                :num="num + 1"
                :nesting-limit="nestingLimit"
                :elseif-limit="elseifLimit"
                :quiz-boards="quizBoards"
                :rendercontentflow-board-id="rendercontentflowBoardId"
                :allowed-actions="allowedActions"
                @input="emitChange"
                ref="else_ifblock_wizard"
            />
        </div>
    </v-sheet>
</template>

<script>
import ExpressionsWizardActionSelector from "./ExpressionsWizardActionSelector.vue";
import ExpressionsWizardIfblock from "./ExpressionsWizardIfblock.vue";

export default {
    name: "ExpressionsWizardComplexIfCreator",

    components: {
        ExpressionsWizardActionSelector,
        ExpressionsWizardIfblock
    },

    props: {
        allowedActions: {
            type: Array,
            required: true
        },
        elseifLimit: {
            type: Number,
            required: true
        },
        nestingLimit: {
            type: Number,
            required: true
        },
        num: {
            type: Number,
            required: true
        },
        quizBoards: {
            type: Array,
            required: true
        },
        rendercontentflowBoardId: {
            type: String,
            required: true
        },
        value: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            lv: null,

            ifcond_type: null,
            elsecond_type: null
        };
    },

    watch: {
        value: {
            handler() {
                this.lv = {
                    ...this.value
                };

                // Jeżeli warunek else są już określony, to na podstawie jego zawartości ustawiamy typ
                if (this.lv.else != null) {
                    if (typeof this.lv.else === "string") {
                        this.elsecond_type = "action";
                    } else {
                        this.elsecond_type = "ifblock";
                    }
                } else if (this.num >= this.elseifLimit) {
                    this.setupElseCondition("action");
                }
            },
            immediate: true
        }
    },

    methods: {
        setupElseCondition(type) {
            if (type === "action") {
                if (typeof this.lv.else !== "string") {
                    this.lv.else = "";
                }
            } else {
                if (typeof this.lv.else !== "object" || this.lv.else === null) {
                    this.lv.else = {
                        entity_type: "ifblock",
                        if: null,
                        then: "",
                        else: null
                    };
                }
            }
            this.elsecond_type = type;
            this.emitChange();
        },

        validate() {
            const A = [];

            // 1. if
            A.push(this.$refs.if_block_wizard.validate());

            // 2. then
            A.push(this.$refs.then_action_selector.validate());

            // 3. else
            if (this.elsecond_type === "action") {
                A.push(this.$refs.else_action_selector.validate());
            } else if (this.elsecond_type === "ifblock") {
                A.push(this.$refs.else_ifblock_wizard.validate());
            } else {
                A.push(false);
                this.$message({
                    type: "error",
                    msg: "Wybierz rodzaj akcji alternatywnej"
                });
            }

            return A.indexOf(false) === -1;
        },

        emitChange() {
            this.$emit("input", {
                ...this.lv
            });
        }
    }
};
</script>
