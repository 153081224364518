import TextStyle from "@tiptap/extension-text-style";
import { Extension } from "@tiptap/core";

const NAME = "FontSize";
export const FontSize = TextStyle.extend({
    name: NAME,

    addOptions() {
        return {
            types: ["textStyle"]
        };
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    fontSize: {
                        default: null,
                        parseHTML: element => {
                            const FS = element.style.fontSize?.replace(/['"]+/g, "");
                            if (!FS | isNaN(parseInt(FS))) return null;
                            return parseInt(FS);
                        },
                        renderHTML: attributes => {
                            if (!attributes.fontSize) {
                                return {};
                            }

                            return {
                                style: `font-size: ${attributes.fontSize}px`
                            };
                        }
                    },
                    lineHeight: {
                        default: null,
                        parseHTML: element => {
                            const FS = element.style.lineHeight?.replace(/['"]+/g, "");
                            if (!FS | isNaN(parseFloat(FS))) return null;
                            return parseFloat(FS);
                        },
                        renderHTML: attributes => {
                            if (!attributes.lineHeight) {
                                return {};
                            }

                            return {
                                style: `line-height: ${attributes.lineHeight}`
                            };
                        }
                    }
                }
            }
        ];
    },

    addCommands() {
        return {
            setFontSize:
                fontSize =>
                ({ chain }) => {
                    return chain().setMark("textStyle", { fontSize, lineHeight: 1.25 }).run();
                },
            clearFontSize:
                () =>
                ({ chain }) => {
                    return chain()
                        .setMark("textStyle", { fontSize: null, lineHeight: null })
                        .removeEmptyTextStyle()
                        .run();
                }
        };
    }
});

export const SELECT_PREDEFINED_ITEMS = [
    {
        text: "8px",
        value: 8
    },
    {
        text: "10px",
        value: 10
    },
    {
        text: "12px",
        value: 12
    },
    {
        text: "14px",
        value: 14
    },
    {
        text: "16px (domyślny)",
        value: 16
    },
    {
        text: "18px",
        value: 18
    },
    {
        text: "24px",
        value: 24
    },
    {
        text: "36px",
        value: 36
    },
    {
        text: "48px",
        value: 48
    },
    {
        text: "64px",
        value: 64
    }
];
