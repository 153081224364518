export function isAuthDataValid(auth_data) {
    if (
        auth_data &&
        auth_data.access_token &&
        auth_data.access_token != "" &&
        auth_data.refresh_token &&
        auth_data.refresh_token != "" &&
        auth_data.access_token_exp_date &&
        !isNaN(auth_data.access_token_exp_date) &&
        auth_data.access_token_exp_date > 0 &&
        auth_data.refresh_token_exp_date &&
        !isNaN(auth_data.refresh_token_exp_date) &&
        auth_data.refresh_token_exp_date > 0
    ) {
        // jeżeli refresh_token się nie przeterminował to można auth data podłożyć do store - axios już sobie odświeży co trzeba podczas pobierania danych użytkownika
        if (auth_data.refresh_token_exp_date > Date.now() + 120000) {
            //refresh_token jest uznawany za ważny, jeżeli ma jeszcze chociaż 120s czasu życia
            return true;
        }
    }
    return false;
}

export function accessTokenNeedsRefresh(access_token_exp_date) {
    return access_token_exp_date <= Date.now() + 60 * 1000;
}

export function refreshTokenCanBeUsed(rt_exp_date) {
    return rt_exp_date >= Date.now() + 60 * 1000;
}
